import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

class User extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          error: null,
          activeNav: 1,
          isLoaded: false,
          user: [],
          roles: []
        };
    }
    
    componentDidMount() {
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
            method: 'get',
            headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
            })
            .then(res => res.json())
            .then(
            (result) => {
                this.setState({
                isLoaded: true,
                user: result,
                });
            },
            (error) => {
                this.setState({
                isLoaded: true,
                error
                });
            }
            )
        };

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
            method: 'get',
            headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
            })
            .then(res => res.json())
            .then(
            (result) => {
                this.setState({
                roles: result
                });
            },
            (error) => {
                this.setState({
                isLoaded: true,
                error
                });
            }
            )
        };
    }

    render() {
        return (
            <>
            <GenericHeader />
            {/* Page content */}
            <Container className="mt--7" fluid>
            <Row>
                <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                        <h3 className="mb-0">Πληροφορίες χρήστη</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                        <Button
                            color="primary"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                            size="sm"
                        >
                            Αποθήκευση αλλαγών
                        </Button>
                        </Col>
                    </Row>
                    </CardHeader>
                    <CardBody>
                    <Form>
                    <h6 className="heading-small text-muted mb-4">
                        Βασικα στοιχεια
                        </h6>
                        <div className="pl-lg-4">
                        <Row>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-username"
                                >
                                Ονοματεπώνυμο
                                </label>
                                <Input
                                className="form-control-alternative"
                                defaultValue={this.state.user.DisplayName}
                                id="input-username"
                                placeholder=""
                                type="text"
                                />
                            </FormGroup>
                            </Col>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-email"
                                >
                                Email 
                                </label>
                                <Input
                                className="form-control-alternative"
                                defaultValue={this.state.user.Email}
                                id="input-email"
                                placeholder="email@example.com"
                                type="email"
                                />
                            </FormGroup>
                            </Col>
                            {/* <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                            >
                                Ρόλος
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={this.state.user.Roles}
                                id="input-first-name"
                                placeholder="First name"
                                type="select"
                            > 
                            { this.state.roles.map((dynamicRoles) =>
                                <option>{dynamicRoles.Name}</option>
                            )}
                            </Input>
                            </FormGroup>
                            </Col> */}
                        </Row>
                        </div>
                    </Form>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            </Container>
        </>
        );
    }
}

export default User;
