import { Steps, Panel, Notification, Timeline, Paragraph } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { saveAs } from 'file-saver';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';

import 'antd/dist/antd.css';
import React, { useState } from "react";

import {
    Collapse,
    Card,
    Form,
    FormGroup,
    Input,
    Table,
    CardHeader,
    CardTitle,
    CardBody,
    Container,
    Row,
    Col,
    Button
} from "reactstrap";

import ReactTooltip from 'react-tooltip';

import InstanceHeader from "components/Headers/InstanceHeader.js";
import { CommonLoading } from 'react-loadingg';
import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import background from "../../assets/img/header-background.jpg";

import axios from 'axios';

class Instance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userAssignedId: null,
            showSecondModal: false,
            error: null,
            isOpen: false,
            step: 0,
            details: [],
            assigned_names: [],
            instance: [],
            workflow: [],
            fileUpload: null,
            steps: [],
            user: [],
            receiverEnomosFiles: [],
            lawyerEnomosFiles: [],
            replies: [],
            extra: [],
            enomosExtra: [],
            answerStep: '',
            answerAction: '',
            answer: '',
            filesEnomos: [],
            consumerEnomosFiles: [],
            showAnswer: 0,
            hasReplies: false,
            loading: true,
            commentDisabled: true,
            file: null,
            declineMessage: '',
            declineDate: '',
            userWhoDeclined: ''
        };
    }

    async componentDidMount() {
        const handle = this.props.match.params.id;

        if (localStorage.getItem('token')) {
            const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/enomos/details/" + handle + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
                .then(res => {
                    this.setState({
                        instance: res.data,
                        details: res.data
                    });


                    this.setState({
                        step: this.state.instance.Status
                    });
                });

            await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/extra/" + handle + "/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            extra: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            error
                        });
                    }
                )

            await fetch(process.env.REACT_APP_API_LINK + "/api/enomos/extra/" + handle + "/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            enomosExtra: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            error
                        });
                    }
                )

            await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            user: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            error
                        });
                    }
                )

            await fetch(process.env.REACT_APP_API_LINK + "/api/user/enomos/get_users_by_role/" + handle + "/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            users: result,
                        });
                        console.log(this.state.users['Lawyers']);
                    },
                    (error) => {
                        this.setState({
                            error
                        });
                    }
                )

            await fetch(process.env.REACT_APP_API_LINK + "/api/enomos/get_assigned_names/" + handle + "/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            assigned_names: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            error
                        });
                    }
                )

            await fetch(process.env.REACT_APP_API_LINK + "/api/replies/enomos/all/" + handle + "/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            replies: result.replies,
                            hasReplies: result.hasReplies
                        });

                        if (result.hasReplies) {
                            var counter = 0;
                            result.replies.forEach(function (arrayItem) {
                                arrayItem.user = result.users[counter];
                                counter++;

                            });
                            this.setState({
                                replies: result.replies,
                            });

                        }
                    },
                    (error) => {
                        this.setState({
                            error
                        });
                    }
                )



            await fetch(process.env.REACT_APP_API_LINK + "/api/files/enomos/instance/" + handle + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            filesEnomos: result,
                            loading: false
                        });
                        let allEnomosFiles = this.state.filesEnomos
                        let filteredConsumerEnomosFiles = allEnomosFiles.filter(element => {
                            if (element.UserType == 0) return element
                        })
                        let filteredReceiverEnomosFiles = allEnomosFiles.filter(element => {
                            if (element.UserType == 2) return element
                        })
                        let filteredLawyerEnomosFiles = allEnomosFiles.filter(element => {
                            if (element.UserType == 1 && element.ReceiverType == 2) return element
                        })
                        this.setState({
                            consumerEnomosFiles: filteredConsumerEnomosFiles,
                            lawyerEnomosFiles: filteredLawyerEnomosFiles,
                            receiverEnomosFiles: filteredReceiverEnomosFiles
                        });
                    },
                    (error) => {
                        this.setState({
                            error
                        });
                    }
                )
        };
    }

    toggleCollapse = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen, // Toggle the isOpen state
        }));
    };

    downloadAllFiles = (files) => {
        files.forEach(f => {
            fetch(f.Link, {
                headers: {
                    'Content-Type': f.Type
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    saveAs(blob, f.FileName);
                });
        })
    };

    handleDownload = (link, contentType, name) => {
        fetch(link, {
            headers: {
                'Content-Type': contentType
            }
        })
            .then(response => response.blob())
            .then(blob => {
                saveAs(blob, name);
            });
    };

    GiveAnswer = stepStatus => {
        this.setState({
            showAnswer: 1
        });
    }

    updateAnswer(evt) {
        if (evt.target.value != '') {
            this.setState({ commentDisabled: false })
        }
        else this.setState({ commentDisabled: true })

        this.setState({
            answer: evt.target.value
        })
    }

    sendAnswer(evt) {
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/replies/enomos/new/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify({
                    'InstanceId': this.state.instance.Id,
                    'UserType': 2,
                    'Message': this.state.answer,
                    'ReceiverType': 1
                })
            })
                .then(data => {
                    fetch(process.env.REACT_APP_API_LINK + "/api/replies/enomos/all/" + this.state.instance.Id + "/", {
                        method: 'get',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/x-www-form-urlencoded'
                        })
                    })
                        .then(res => res.json())
                        .then(
                            (result) => {
                                this.setState({
                                    replies: result.replies,
                                    hasReplies: true
                                });

                                var counter = 0;
                                result.replies.forEach(function (arrayItem) {
                                    arrayItem.user = result.users[counter];
                                    counter++;

                                });
                                this.setState({
                                    replies: result.replies,
                                });
                                window.location.reload(false);

                            },
                            (error) => {
                                this.setState({
                                    error
                                });
                            }
                        )
                })
        }
    }


    updateFile(evt) {
        this.setState({ fileUpload: evt.target.files[0] });
    }

    deleteFile = fileId => {
        if (window.confirm("Είστε σίγουροι ότι θέλετε να διαγράψετε το συγκεκριμένο αρχείο;") == true) {
            if (localStorage.getItem('token')) {
                axios.delete(process.env.REACT_APP_API_LINK + '/api/files/enomos/' + fileId + "/", {
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                })
                    .then(res => {
                        axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
                            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                        })
                            .then(res => {
                                this.setState({
                                    loading: false,
                                    files: res.data
                                });
                                window.location.reload(false)
                            })
                    })
            }
        }
        else { }
    }

    uploadFile(evt, UserType, ReceiverType) {
        const formData = new FormData();

        // Update the formData object 
        formData.append(
            "files",
            evt.target.files[0]
        );
        fetch(
            process.env.REACT_APP_FILEMANAGER_LINK,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                fetch(process.env.REACT_APP_API_LINK + '/api/files/enomos/new/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }),
                    body: JSON.stringify({
                        'Name': result[0].name,
                        'Path': result[0].path,
                        'Size': result[0].size,
                        'Type': result[0].type,
                        'InstanceId': this.state.instance.Id,
                        'StepId': '674d15b5-2717-40c2-b119-489572b8501a',
                        'ActionId': '3afe3b80-db44-4407-93c8-c17dde55221b',
                        'UserType': UserType,
                        'ReceiverType': ReceiverType
                    })
                })
                    .then(data => {

                        axios.get(process.env.REACT_APP_API_LINK + "/api/files/enomos/status/" + this.state.instance.Id + "/", {
                            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                        })
                            .then(res => {
                                this.setState({
                                    loading: false,
                                    files: res.data
                                });
                                let allEnomosFiles = this.state.filesEnomos
                                let filteredConsumerEnomosFiles = allEnomosFiles.filter(element => {
                                    if (element.UserType == 0) return element
                                })
                                let filteredReceiverEnomosFiles = allEnomosFiles.filter(element => {
                                    if (element.UserType == 2) return element
                                })
                                let filteredLawyerEnomosFiles = allEnomosFiles.filter(element => {
                                    if (element.UserType == 1 && element.ReceiverType == 2) return element
                                })
                                this.setState({
                                    consumerEnomosFiles: filteredConsumerEnomosFiles,
                                    lawyerEnomosFiles: filteredLawyerEnomosFiles,
                                    receiverEnomosFiles: filteredReceiverEnomosFiles
                                });
                            })

                        fetch(process.env.REACT_APP_API_LINK + "/api/files/enomos/instance/" + this.state.instance.Id + '/', {
                            method: 'get',
                            headers: new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type': 'application/x-www-form-urlencoded'
                            })
                        })
                            .then(res => res.json())
                            .then(
                                (result) => {
                                    this.setState({
                                        filesEnomos: result,
                                        loading: false
                                    });
                                    let allEnomosFiles = this.state.filesEnomos
                                    let filteredConsumerEnomosFiles = allEnomosFiles.filter(element => {
                                        if (element.UserType == 0) return element
                                    })
                                    let filteredReceiverEnomosFiles = allEnomosFiles.filter(element => {
                                        if (element.UserType == 2) return element
                                    })
                                    let filteredLawyerEnomosFiles = allEnomosFiles.filter(element => {
                                        if (element.UserType == 1 && element.ReceiverType == 2) return element
                                    })
                                    this.setState({
                                        consumerEnomosFiles: filteredConsumerEnomosFiles,
                                        lawyerEnomosFiles: filteredLawyerEnomosFiles,
                                        receiverEnomosFiles: filteredReceiverEnomosFiles
                                    });
                                },
                                (error) => {
                                    this.setState({
                                        error
                                    });
                                }
                            )
                    })
            });
    }

    setShowSecondModal = () => {
        this.setState({ showSecondModal: !this.state.showSecondModal });
    }

    assignInstanceToCompany = () => {
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/enomos/' + this.state.instance.Id + '/assign_to_company/' + this.state.userAssignedId + '/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                })
            })
                .then(data => {
                    window.location.reload(false);
                })
        }
    }

    updateUserAssignedId = (evt) => {
        // Assuming the value passed is the lawyer Id
        this.setState({ userAssignedId: evt.value }, () => {
            console.log('Assigned Lawyer Id:', this.state.userAssignedId);
        });
    };


    render() {
        const userOptions = this.state.users?.['HeadUsersAndUsers']?.map((lawyer) => {
            return { value: lawyer.id, label: lawyer.name };
        }) || [];
        if (this.state.loading) {
            return (
                <>
                    <GenericHeader />
                    <CommonLoading />
                </>
            )
        }
        else {
            return (
                <>
                    <InstanceHeader />
                    <div className="header pb-4" style={{ backgroundImage: `url(${background})` }}>
                        <Container fluid>
                            <div className="header-body">
                                <Row>
                                    <Col lg="8" xl="12">
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                        >
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                            Υποβολή Αιτήματος
                                                        </span>
                                                        <CardBody>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Αριθμός Αιτήματος: </span> &nbsp; {this.state.instance.SerialNumber}</Row>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Ημερομηνία Έναρξης: </span> &nbsp; {Moment(this.state.instance.StartedAt).locale('el').format('dddd, DD MMM YYYY')}</Row>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Διαμεσολαβητής: </span> &nbsp; {this.state.assigned_names[1]}</Row>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Εκπρόσωπος Εταιρείας: </span> &nbsp; {this.state.assigned_names[0]}</Row>
                                                        </CardBody>
                                                    </div>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>

                    <Container className="mt--4" fluid>
                        <Row className="mt-5">
                            <Modal

                                show={this.state.showSecondModal}
                                onHide={this.setShowSecondModal}
                                dialogClassName="modal-90w"
                                aria-labelledby="example-custom-modal-styling-title"
                                style={{ opacity: 1 }}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="example-custom-modal-styling-title">
                                        <strong style={{ fontSize: '18px', textAlign: 'center' }}>Ορισμός Εκπρόσωπου</strong>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <div className="pl-lg-12">
                                            <Row>
                                                <Col xs={12} md={12}>
                                                    <Select
                                                        onChange={this.updateUserAssignedId}
                                                        options={userOptions}
                                                        placeholder="Επιλέξτε Εκπρόσωπο"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        color="primary"
                                        onClick={() => { this.assignInstanceToCompany() }}
                                        size="sm"
                                    >
                                        Ολοκλήρωση
                                    </Button>

                                </Modal.Footer>
                            </Modal>
                            <Col className="mb-5 mb-xl-0" xl="12">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row className="align-items-center">
                                            <Col xs="6">
                                                <h3 className="mb-0">Πορεία Αιτήματος</h3>
                                            </Col>
                                            <Col className="text-right" xs="6">
                                                {this.state.user.PersonRole == 'Head User' && this.state.instance.Status == 1 ?
                                                    <>
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            onClick={this.setShowSecondModal}
                                                        >
                                                            {this.state.instance.UserAssignedId ? 'Αλλαγή Εκπρόσωπου Εταιρείας' : 'Ορισμός Εκπρόσωπου Εταιρείας'}
                                                        </Button>
                                                    </> : <></>}
                                                <Button
                                                    color="warning"
                                                    href={"/admin/instancexport/" + this.state.instance.Id}
                                                    size="sm"
                                                >
                                                    Εξαγωγή Αιτήματος
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            <Steps current={this.state.step}>
                                                <Steps.Item title="Υποβολή αιτήματος" description="Το αίτημα υποβλήθηκε." />
                                                <Steps.Item title="Επεξεργασία αιτήματος" description="Το αίτημα επεξεργάζεται." />
                                                <Steps.Item title="Ολοκλήρωση αιτήματος" description="Το αίτημα ολοκληρώθηκε." />
                                            </Steps>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {this.state.instance.DetailsShared == true ?
                            <>
                                <Row className='mt-4'>
                                    <Col className="order-xl-1" xl='12'>
                                        <Card className="bg-secondary shadow">
                                            <CardHeader className="bg-white border-0">
                                                <Row className="align-items-center">
                                                    <Col xs="6">
                                                        <h3 className="mb-0">Αίτηση προς Ενεργειακό Διαμεσολαβητή (Α/Α: {this.state.instance.SerialNumber})</h3>
                                                    </Col>
                                                    <Col className="text-right" xs="6">
                                                        {!this.state.isOpen ?
                                                            <>
                                                                <Button
                                                                    className="btn btn-link" // You can style this to your liking
                                                                    onClick={this.toggleCollapse}
                                                                    size='sm'
                                                                    color='info'
                                                                >
                                                                    Προβολή στοιχείων
                                                                </Button>
                                                            </> : <>
                                                                <Button
                                                                    className="btn btn-link" // You can style this to your liking
                                                                    onClick={this.toggleCollapse}
                                                                    size='sm'
                                                                    color='info'
                                                                >
                                                                    Απόκρυψη στοιχείων
                                                                </Button>
                                                            </>}
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                <Form>
                                                    <Collapse isOpen={this.state.isOpen}>

                                                        <div className="pl-lg-4">
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-email"
                                                                        >
                                                                            Πατρώνυμο
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-email"
                                                                            placeholder="Πατρώνυμο"
                                                                            defaultValue={this.state.enomosExtra.fathername}
                                                                            type="text"
                                                                            disabled={true}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-email"
                                                                        >
                                                                            Αριθμός Ταυτότητας
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-email"
                                                                            placeholder="Αριθμός Ταυτότητας"
                                                                            defaultValue={this.state.enomosExtra.id}
                                                                            type="text"
                                                                            disabled={true}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-email"
                                                                        >
                                                                            Ταχυδρομική Διεύθυνση
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-email"
                                                                            placeholder="Ταχυδρομική Διεύθυνση"
                                                                            defaultValue={this.state.enomosExtra.postalcode}
                                                                            type="text"
                                                                            disabled={true}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row><Row className='mt-4'>

                                                            </Row>

                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-email"
                                                                        >
                                                                            Το αναφερόμενο θέμα αποτελεί αντικείμενο δικαστικών διαδικασιών;*
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-email"
                                                                            defaultValue={this.state.enomosExtra.courtproceedings}
                                                                            placeholder="Το αναφερόμενο θέμα αποτελεί αντικείμενο δικαστικών διαδικασιών;"
                                                                            type="text"
                                                                            disabled={true}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-email"
                                                                        >
                                                                            Έχετε απευθυνθεί σε άλλον φορέα εναλλακτικής επίλυσης διαφορών;
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-email"
                                                                            placeholder="Έχετε απευθυνθεί σε άλλον φορέα εναλλακτικής επίλυσης διαφορών;"
                                                                            defaultValue={this.state.enomosExtra.advocate}
                                                                            type="text"
                                                                            disabled={true}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-email"
                                                                        >
                                                                            Παρακαλούμε περιγράψτε το παράπονο σας και τους λόγους που μετά τις απαντήσεις που λάβατε μέσω του myRAE επιθυμείτε να προχωρήσετε στις υπηρεσίες του ENOMOS
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-email"
                                                                            placeholder="Παρακαλούμε περιγράψτε το παράπονο σας και τους λόγους που μετά τις απαντήσεις που λάβατε μέσω του myRAE επιθυμείτε να προχωρήσετε στις υπηρεσίες του ENOMOS"
                                                                            defaultValue={this.state.enomosExtra.reasoning}
                                                                            type="textarea"
                                                                            disabled={true}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-email"
                                                                        >
                                                                            Παρακαλούμε περιγράψτε τι είναι αυτό που ζητάτε από τον Ενεργειακό Μεσολαβητή. Ποια είναι η πρόταση/απαίτηση σας για την επίλυση του θέματος σας.
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-email"
                                                                            placeholder="Παρακαλούμε περιγράψτε τι είναι αυτό που ζητάτε από τον Ενεργειακό Μεσολαβητή. Ποια είναι η πρόταση/απαίτηση σας για την επίλυση του θέματος σας."
                                                                            defaultValue={this.state.enomosExtra.suggestions}
                                                                            type="textarea"
                                                                            disabled={true}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="order-xl-1" xl='12'>
                                                                    <Card className="bg-secondary shadow">
                                                                        <CardHeader className="bg-white border-0">
                                                                            <Row className="align-items-center">
                                                                                <Col xs="6">
                                                                                    <h3 className="mb-0">Στοιχεία Εξουσιοδοτημένου Προσώπου</h3>
                                                                                </Col>
                                                                                <Col className="text-right" xs="6">
                                                                                    {!this.state.isThirdOpen ?
                                                                                        <>
                                                                                            <Button
                                                                                                className="btn btn-link" // You can style this to your liking
                                                                                                onClick={this.toggleThirdCollapse}
                                                                                                size='sm'
                                                                                                color='info'
                                                                                            >
                                                                                                Προβολή στοιχείων
                                                                                            </Button>
                                                                                        </> : <>
                                                                                            <Button
                                                                                                className="btn btn-link" // You can style this to your liking
                                                                                                onClick={this.toggleThirdCollapse}
                                                                                                size='sm'
                                                                                                color='info'
                                                                                            >
                                                                                                Απόκρυψη στοιχείων
                                                                                            </Button>
                                                                                        </>}
                                                                                </Col>
                                                                            </Row>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <Form>
                                                                                <Collapse isOpen={this.state.isThirdOpen}>

                                                                                    <Row>
                                                                                        <Col lg="6">
                                                                                            <FormGroup>
                                                                                                <label
                                                                                                    className="form-control-label"
                                                                                                    htmlFor="input-email"
                                                                                                >
                                                                                                    Όνομα
                                                                                                </label>
                                                                                                <Input
                                                                                                    className="form-control-alternative"
                                                                                                    id="input-email"
                                                                                                    placeholder="Όνομα"
                                                                                                    defaultValue={this.state.enomosExtra.authorizedname}
                                                                                                    type="text"
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col lg="6">
                                                                                            <FormGroup>
                                                                                                <label
                                                                                                    className="form-control-label"
                                                                                                    htmlFor="input-email"
                                                                                                >
                                                                                                    Επώνυμο
                                                                                                </label>
                                                                                                <Input
                                                                                                    className="form-control-alternative"
                                                                                                    id="input-email"
                                                                                                    placeholder="Επώνυμο"
                                                                                                    defaultValue={this.state.enomosExtra.authorizedsurname}
                                                                                                    type="text"
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col lg="6">
                                                                                            <FormGroup>
                                                                                                <label
                                                                                                    className="form-control-label"
                                                                                                    htmlFor="input-email"
                                                                                                >
                                                                                                    Πατρώνυμο
                                                                                                </label>
                                                                                                <Input
                                                                                                    className="form-control-alternative"
                                                                                                    id="input-email"
                                                                                                    placeholder="Πατρώνυμο"
                                                                                                    defaultValue={this.state.enomosExtra.authorizedfathername}
                                                                                                    type="text"
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col lg="6">
                                                                                            <FormGroup>
                                                                                                <label
                                                                                                    className="form-control-label"
                                                                                                    htmlFor="input-email"
                                                                                                >
                                                                                                    Αριθμός Ταυτότητας
                                                                                                </label>
                                                                                                <Input
                                                                                                    className="form-control-alternative"
                                                                                                    id="input-email"
                                                                                                    placeholder="Αριθμός Ταυτότητας"
                                                                                                    defaultValue={this.state.enomosExtra.authorizedid}
                                                                                                    type="text"
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col lg="6">
                                                                                            <FormGroup>
                                                                                                <label
                                                                                                    className="form-control-label"
                                                                                                    htmlFor="input-email"
                                                                                                >
                                                                                                    ΑΦΜ
                                                                                                </label>
                                                                                                <Input
                                                                                                    className="form-control-alternative"
                                                                                                    id="input-email"
                                                                                                    placeholder="ΑΦΜ"
                                                                                                    defaultValue={this.state.enomosExtra.authorizedafm}
                                                                                                    type="text"
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col lg="6">
                                                                                            <FormGroup>
                                                                                                <label
                                                                                                    className="form-control-label"
                                                                                                    htmlFor="input-email"
                                                                                                >
                                                                                                    Ταχυδρομική Διεύθυνση
                                                                                                </label>
                                                                                                <Input
                                                                                                    className="form-control-alternative"
                                                                                                    id="input-email"
                                                                                                    placeholder="Ταχυδρομική Διεύθυνση"
                                                                                                    defaultValue={this.state.enomosExtra.authorizedpostalcode}
                                                                                                    type="text"
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Collapse>
                                                                            </Form>

                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <hr className="my-4" />
                                                        <div className="pl-lg-4">
                                                            <Row>
                                                                <Col md="6">
                                                                    <h6 className="heading-small text-muted mb-4">
                                                                        Εγγραφα απο Καταναλωτη
                                                                    </h6>
                                                                </Col>
                                                                {this.state.consumerEnomosFiles.length > 0 &&
                                                                    <>
                                                                        <Col md="6">
                                                                            <Button
                                                                                color="success"
                                                                                size='sm'
                                                                                onClick={() => { this.downloadAllFiles(this.state.consumerEnomosFiles) }}
                                                                            >
                                                                                Λήψη όλων
                                                                            </Button>
                                                                        </Col>
                                                                    </>}

                                                                <Table className="align-items-center table-flush" responsive>
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Ονομα αρχειου</th>
                                                                            <th scope="col">Ημερομηνια Αναρτησης</th>
                                                                            <th scope="col">Ενεργειες</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.consumerEnomosFiles.map((dynamicFiles) =>
                                                                                <tr>
                                                                                    <td>{dynamicFiles.FileName}</td>
                                                                                    <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                                                                    <td>
                                                                                        <Button
                                                                                            color="primary"
                                                                                            href={dynamicFiles.Link}
                                                                                            target="_blank"
                                                                                            title="Προβολή"
                                                                                            className="btn-sm btn-primary"
                                                                                        >
                                                                                            <i className="fas fa-eye" />
                                                                                        </Button>
                                                                                        <Button
                                                                                            onClick={() => { this.handleDownload(dynamicFiles.Link, dynamicFiles.Type, dynamicFiles.FileName) }}
                                                                                            color="success"
                                                                                            size='sm'
                                                                                            title="Λήψη"
                                                                                        >
                                                                                            <i className="fas fa-download" />
                                                                                        </Button>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </Row>
                                                        </div>
                                                    </Collapse>
                                                </Form>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </> : <></>}
                        <Row className='mt-4'>
                            <Col className="order-xl-1" xl={6}>
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="mb-0">Στοιχεία Αίτησης (Α/Α: myRAE-{this.state.extra.serial})</h3>
                                            </Col>
                                            <Col className="text-right" xs="4">
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <h6 className="heading-small text-muted mb-4">
                                                Βασικες πληροφοριες
                                            </h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Όνομα Κατόχου
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Όνομα Κατόχου"
                                                                defaultValue={this.state.extra.name}
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Επίθετο Κατόχου
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Επίθετο Κατόχου"
                                                                defaultValue={this.state.extra.last}
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                ΑΦΜ Κατόχου
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="ΑΦΜ Κατόχου"
                                                                defaultValue={this.state.extra.afm}
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Αριθμός Παροχής
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Αριθμός Παροχής"
                                                                defaultValue={this.state.extra.num}
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Κατηγορία Αιτήματος
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                defaultValue={this.state.extra.category}
                                                                placeholder="Κατηγορία"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Είδος Ενέργειας
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Είδος Ενέργειας"
                                                                defaultValue={this.state.extra.energy}
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Είδος Παραλήπτη
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Είδος Παραλήπτη"
                                                                defaultValue={this.state.extra.typercvr}
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Παραλήπτης
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Παραλήπτης"
                                                                defaultValue={this.state.extra.rcvr}
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <hr className="my-4" />
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col md="12">
                                                        <h6 className="heading-small text-muted mb-4">
                                                            Σχολια/Παρατηρησεις Αιτηματος
                                                        </h6>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-email"
                                                            defaultValue={this.state.extra.text}
                                                            placeholder="Σχόλια/Παρατηρήσεις Αιτήματος"
                                                            type="textarea"
                                                            disabled={true}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>


                            <Col xs="6">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="12">
                                                <h4 className="mb-0">Ανταλλαγή Μηνυμάτων με τον Διαμεσολαβητή</h4>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        {this.state.hasReplies &&
                                            <Col md="11" style={{ height: "38vh", overflowY: "scroll" }}>
                                                {this.state.replies
                                                    .filter(dynamicReplies =>
                                                        (dynamicReplies.UserType === 1 || dynamicReplies.UserType === 2) &&
                                                        (dynamicReplies.ReceiverType === 1 || dynamicReplies.ReceiverType === 2)
                                                    )
                                                    .map(dynamicReplies => (
                                                        <div
                                                            className="container"
                                                            style={{
                                                                textAlign: dynamicReplies.UserType === 2 ? "right" : "left",
                                                                background: dynamicReplies.UserType === 2 ? "powderblue" : "lightgray",
                                                                color: "black",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            <strong
                                                                style={{ margin: "0px" }}
                                                                className={dynamicReplies.UserType === 2 ? "time-right" : "time-left"}
                                                            >
                                                                {dynamicReplies.user}
                                                            </strong>
                                                            <p
                                                                style={{ margin: "0px" }}
                                                                className={dynamicReplies.UserType === 2 ? "time-right" : "time-left"}
                                                            >
                                                                {Moment(dynamicReplies.DateCreated)
                                                                    .locale("el")
                                                                    .format("DD MMM YYYY HH:mm:ss")}
                                                            </p>
                                                            <h4>
                                                                <i>{dynamicReplies.Message}</i>
                                                            </h4>
                                                        </div>
                                                    ))}
                                            </Col>
                                        }

                                    </CardBody>
                                    < hr />
                                    <Row className="align-items-center">
                                        <Col xs="11">

                                            {this.state.instance.UserAssignedId == this.state.user.Id ?
                                                <>
                                                    <div className="pl-lg-4" style={{ width: '100%' }}>
                                                        <h6 className="heading-small text-muted mb-4">
                                                            Νεο Σχολιο
                                                        </h6>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-email"
                                                            placeholder="Σχόλιο"
                                                            type="textarea"
                                                            disabled={this.state.instance.Status != 1}
                                                            onChange={evt => this.updateAnswer(evt)}
                                                        />
                                                        <br />
                                                        <Col lg="10">
                                                            <label className="btn-sm btn-primary" htmlFor='file-answer' >
                                                                Επιλογή αρχείου
                                                            </label>
                                                            <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative'} aria-hidden="true" style={{ marginLeft: '5px', marginTop: '3px' }}></i>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id='file-answer'
                                                                style={{ visibility: "hidden" }}
                                                                type='file'
                                                                onChange={evt => this.uploadFile(evt, 2, 1)}
                                                            />


                                                            <ReactTooltip id={'info-form-control-alternative'} type='info'>
                                                                <span>Μέγιστο μέγεθος αρχείο 5ΜΒ.</span>
                                                                <div id="imageattooltip"></div>
                                                            </ReactTooltip>
                                                        </Col>
                                                        <br />
                                                        <Button
                                                            color="success"
                                                            onClick={evt => this.sendAnswer()}
                                                            size="sm"
                                                            style={{ width: '100%' }}
                                                            disabled={this.state.commentDisabled}
                                                        >
                                                            Αποστολή προς Διαμεσολαβητή
                                                        </Button>
                                                    </div>
                                                    < hr />
                                                </> : <></>}
                                            <div className="pl-lg-4" style={{ width: '100%' }}>
                                                <Row>
                                                    <Col md="6">
                                                        <h6 className="heading-small text-muted mb-4">
                                                            Εγγραφα από Διαμεσολαβητη
                                                        </h6>
                                                    </Col>
                                                    {this.state.lawyerEnomosFiles.length > 1 &&
                                                        <>
                                                            <Col md="6">
                                                                <Button
                                                                    color="success"
                                                                    size='sm'
                                                                    onClick={() => { this.downloadAllFiles(this.state.lawyerEnomosFiles) }}
                                                                >
                                                                    Λήψη όλων
                                                                </Button>
                                                            </Col>
                                                        </>}

                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Ονομα αρχειου</th>
                                                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                                                <th scope="col">Ενεργειες</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.lawyerEnomosFiles.map((dynamicFiles) =>
                                                                    <tr>
                                                                        <td>{dynamicFiles.FileName}</td>
                                                                        <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                                                        <td>
                                                                            <Button
                                                                                color="primary"
                                                                                href={dynamicFiles.Link}
                                                                                target="_blank"
                                                                                title="Προβολή"
                                                                                className="btn-sm btn-primary"
                                                                            >
                                                                                <i className="fas fa-eye" />
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() => { this.handleDownload(dynamicFiles.Link, dynamicFiles.Type, dynamicFiles.FileName) }}
                                                                                color="success"
                                                                                size='sm'
                                                                                title="Λήψη"
                                                                            >
                                                                                <i className="fas fa-download" />
                                                                            </Button>
                                                                            {this.state.user.PersonRole == 'Admin'?
                                                                                <>
                                                                                    <Button
                                                                                        color="danger"
                                                                                        onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                                                        size="sm"
                                                                                        title="Διαγραφή"
                                                                                    >
                                                                                        <i className="fas fa-trash" />
                                                                                    </Button>
                                                                                </> : <> </>}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Row>
                                            </div>
                                            < hr />
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col md="6">
                                                        <h6 className="heading-small text-muted mb-4">
                                                            Εγγραφα προς Διαμεσολαβητη
                                                        </h6>
                                                    </Col>
                                                    {this.state.receiverEnomosFiles.length > 0 &&
                                                        <>
                                                            <Col md="6">
                                                                <Button
                                                                    color="success"
                                                                    size='sm'
                                                                    onClick={() => { this.downloadAllFiles(this.state.receiverEnomosFiles) }}
                                                                >
                                                                    Λήψη όλων
                                                                </Button>
                                                            </Col>
                                                        </>}

                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Ονομα αρχειου</th>
                                                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                                                <th scope="col">Ενεργειες</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.receiverEnomosFiles.map((dynamicFiles) =>
                                                                    <tr>
                                                                        <td>{dynamicFiles.FileName}</td>
                                                                        <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                                                        <td>
                                                                            <Button
                                                                                color="primary"
                                                                                href={dynamicFiles.Link}
                                                                                target="_blank"
                                                                                title="Προβολή"
                                                                                className="btn-sm btn-primary"
                                                                            >
                                                                                <i className="fas fa-eye" />
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() => { this.handleDownload(dynamicFiles.Link, dynamicFiles.Type, dynamicFiles.FileName) }}
                                                                                color="success"
                                                                                size='sm'
                                                                                title="Λήψη"
                                                                            >
                                                                                <i className="fas fa-download" />
                                                                            </Button>
                                                                            {this.state.user.PersonRole == 'Admin' || this.state.user.Id == this.state.instance.UserAssignedId ?
                                                                                <>
                                                                                    <Button
                                                                                        color="danger"
                                                                                        onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                                                        size="sm"
                                                                                        title="Διαγραφή"
                                                                                    >
                                                                                        <i className="fas fa-trash" />
                                                                                    </Button>
                                                                                </> : <> </>}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </>
            );
        }
    }
}

export default Instance;
