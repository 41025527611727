import React from "react";
import Moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import Spinner from 'react-bootstrap/Spinner';

import axios from 'axios';

import {
    Button,
    Collapse,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";

class createInstance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            tempId: uuidv4(),
        };
    }

    async componentDidMount() {
        const handle = this.props.match.params.id;

        if (localStorage.getItem('token')) {
            const requestOptions = {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify({
                    "WorkflowId": handle,
                })
            };
            const res = await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/", requestOptions);
            const data = await res.json();
            if (data.status == 400) {
                alert(data.errors);
            }
            else {
                setTimeout(function () {
                    window.location.replace("/admin/instance/" + data.InstanceId)
                }, 4500);
            }
        }
    }

    render() {
        return (
            <>
                <InstanceHeader />
                <div className="header pb-4" style={{ background: "linear-gradient(to right, #FFFFFF, #4A002B)" }}>
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col lg="8" xl="12">
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                    </CardTitle>
                                                    <span className="h4 font-weight-bold mb-0">
                                                        <a href="admin/instances">Αιτήσεις</a> / <a href="/admin/newinstance">Δημιουργία νέας αίτησης : Επιλογή Προτύπου</a> / Δημιουργία νέας αίτησης : Δημιουργία
                                                    </span>
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <Container className="mt--4" fluid>
                    <Row className="mt-5">
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Δημιουργία νέας αίτησης...</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Δημιουργία...</span>
                                    </Spinner>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default createInstance;
