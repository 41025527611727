import React, { useState } from "react";
import { Pie, Doughnut, Line, Bar, HorizontalBar } from 'react-chartjs-2';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";

import { CommonLoading } from 'react-loadingg';

const data = {
    labels: ['Αλλαγή Προμηθευτή', 'Στοιχεία', 'Λογαριασμός', 'Εξυπηρέτηση', 'Πληρωμές', 'Καταγγελία Σύμβασης', 'Σύναψη Σύμβασης'],
    datasets: [
        {
            label: 'Είδος Αιτήματος',
            title: {
                display: true,
                text: 'Ανά είδος δραστηριότητας',
                fontSize: 20
            },
            data: [12, 19, 3, 5, 20, 3, 8],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],


};

class Statistics extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            otherDay: new Date(),
            flag: true,
            array: [],
            user: [],
            data_statsbyenergykind: [],
            data_statsbyuserkind: [],
            data_statsbydates: [],
            data_statsbystate: [],
            data_statsbypendingduration: [],
            data_statsbyusers: [],
            data_statsbyusersreplied: [],
            data_statsbyusersrepliedduration: [],
            data_statsbycategory: [],
            loading: true
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.state.startDate.setMonth(this.state.otherDay.getMonth() - 3);
    }

    async componentDidMount() {
        if (localStorage.getItem('token')) {
            await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            user: result,
                        });
                    }
                )
        };

        this.Stats_by_energy_kind();
        this.Stats_by_user_kind();
        this.Stats_by_date_from_to();
        this.Stats_by_state();
        this.Stats_by_pending_duration();
        this.Stats_by_users();
        this.Stats_by_users_replied();
        this.Stats_by_users_replied_pending_duration();
        this.Stats_by_category();
    }

    diffDays(date, otherDate) {
        let difference = Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

        if (difference < 93 && difference > 0)
            return true;
        else
            return false;
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
        if (this.diffDays(date, this.state.otherDay)) {
            document.getElementById('datepicker_error').style.display = 'none';
            document.getElementById('LineChart').style.display = 'block';
            this.getDates_call(date, this.state.otherDay);

            if (localStorage.getItem('token')) {
                fetch(process.env.REACT_APP_API_LINK + '/api/stats/statsbydatesubmitted/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        'fromdate': date.toISOString().slice(0, 10),
                        'todate': this.state.otherDay.toISOString().slice(0, 10)
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        this.setState({
                            data_statsbydates: data
                        })

                    })
            }

        }

        else {
            document.getElementById('datepicker_error').style.display = 'block';
            document.getElementById('datepicker_error').innerHTML = 'Παρακαλώ επιλέξτε εύρος εντός 90 ημερών';
            document.getElementById('LineChart').style.display = 'none';
        }

    }

    handleChange2(date) {
        this.setState({
            otherDay: date
        })


        if (this.diffDays(this.state.startDate, date)) {
            document.getElementById('datepicker_error').style.display = 'none';
            document.getElementById('LineChart').style.display = 'block';
            this.getDates_call(this.state.startDate, date);

            if (localStorage.getItem('token')) {
                fetch(process.env.REACT_APP_API_LINK + '/api/stats/statsbydatesubmitted/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        'fromdate': this.state.startDate.toISOString().slice(0, 10),
                        'todate': date.toISOString().slice(0, 10)
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        this.setState({
                            data_statsbydates: data
                        })

                    })
            }

        }
        else {
            document.getElementById('datepicker_error').style.display = 'block';
            document.getElementById('datepicker_error').innerHTML = 'Παρακαλώ επιλέξτε εύρος εντός 90 ημερών';
            document.getElementById('LineChart').style.display = 'none';
            // this.getDates_call(this.state.startDate, this.state.otherDay);
        }

    }

    getDates(startDate, endDate) {
        var dates = [],
            currentDate = startDate,
            addDays = function (days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            };
        while (currentDate <= endDate) {

            dates.push(currentDate.toLocaleDateString('en-GB'));
            currentDate = addDays.call(currentDate, 1);
        }
        this.state.array = dates;
        return dates;
    };

    getDates_call(date, otherDate) {
        var dates = this.getDates(date, otherDate);
    }

    Stats_by_energy_kind = () => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/stats/statsbyenergykind/', {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                })
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        data_statsbyenergykind: data
                    })

                })
        }
    }

    Stats_by_user_kind = () => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/stats/statsbyuserkind/', {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                })
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        data_statsbyuserkind: data
                    })

                })
        }
    }

    Stats_by_date_from_to = () => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/stats/statsbydatesubmitted/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify({
                    'fromdate': this.state.startDate.toISOString().slice(0, 10),
                    'todate': this.state.otherDay.toISOString().slice(0, 10)
                })
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        data_statsbydates: data
                    })

                })
        }
    }

    Stats_by_state = () => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/stats/statsbystate/', {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                })
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        data_statsbystate: data
                    })

                })
        }
    }

    Stats_by_pending_duration = () => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/stats/statsbypendingduration/', {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                })
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        data_statsbypendingduration: data
                    })

                })
        }
    }

    Stats_by_users = () => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/stats/statsbyusers/', {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                })
            })
                .then(res => res.json())
                .then(data => {

                    this.setState({
                        data_statsbyusers: data,
                        loading: false
                    })

                })
        }
    }

    Stats_by_users_replied = () => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/stats/statsbyreply/', {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                })
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        data_statsbyusersreplied: data
                    })

                })
        }
    }

    Stats_by_users_replied_pending_duration = () => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/stats/statsbyreplyduration/', {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                })
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        data_statsbyusersrepliedduration: data
                    })

                })
        }
    }

    Stats_by_category = () => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/stats/statsbycategory/', {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                })
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        data_statsbycategory: data
                    })

                })
        }
    }
    //   // Usage


    render() {
        if (this.state.loading) {
            return (
                <CommonLoading id="loaderforstatsbyuser" />
            )
        }
        this.getDates_call(this.state.startDate, this.state.otherDay);

        // this.Stats_by_energy_kind();
        // const options = {
        //     title: {
        //         text: "Basic Column Chart in React"
        //     },
        //     data: [{
        //         type: "column",
        //         dataPoints: [
        //             { label: "Apple", y: 10 },
        //             { label: "Orange", y: 15 },
        //             { label: "Banana", y: 25 },
        //             { label: "Mango", y: 30 },
        //             { label: "Grape", y: 28 }
        //         ]
        //     }],


        const state = {
            labels: this.state.array,
            datasets: [
                {
                    label: 'Αριθμός Αιτημάτων',
                    backgroundColor: [
                        '#B21F00',
                        '#C9DE00',
                        '#2FDE00',
                        '#00A6B4',
                        '#6800B4'
                    ],
                    hoverBackgroundColor: [
                        '#501800',
                        '#4B5000',
                        '#175000',
                        '#003350',
                        '#35014F'
                    ],
                    label: 'Αριθμός Αιτημάτων',
                    fill: false,
                    borderColor: "#0839cc",
                    // borderDash: [3, 3],
                    backgroundColor: "#ffc745",
                    pointBackgroundColor: "#ff0000",
                    pointBorderColor: "#55bae7",
                    pointHoverBackgroundColor: "#55bae7",
                    pointHoverBorderColor: "#55bae7",
                    data: this.state.data_statsbydates
                    // data: [5, 10, 20, 15, 30, 40, 10, 5, 3, 28, 12, 17, 27, 5, 10, 20, 15, 30, 40, 10, 5, 3, 28, 12, 17, 27, 5, 10, 20, 15, 30, 40, 10, 5, 3, 28, 12, 17, 27, 5, 10, 20, 15, 30, 40, 10, 5, 3, 28, 12, 17, 27, 5, 10, 20, 15, 30, 40, 10, 5, 3, 28, 12, 17, 27, 30, 40, 10, 5, 3, 28, 12, 17, 27, 30, 40, 10, 5, 3, 28, 12, 17, 27, 30, 40, 10, 5, 3, 28, 12, 17, 50]
                }
            ],



        }



        // const [startDate, setStartDate] = new Date();
        return (
            <React.Fragment>
                <GenericHeader style={{ zIndex: '1' }} />

                <Container className="mt--8" fluid>
                    {/* <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <Pie height="70"
                                                data={mystate}
                                                options={{
                                                    title: {
                                                        display: true,
                                                        text: 'Ανά είδος ενέργειας',
                                                        fontSize: 20
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: 'right'
                                                    }
                                                }}
                                            />

                                        </div>
                                        <div className="col text-rig ht">
                                            <Doughnut height="70"
                                                data={state}
                                                options={{
                                                    title: {
                                                        display: true,
                                                        text: 'Ανά είδος δραστηριότητας',
                                                        fontSize: 20
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: 'right'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Row>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row> */}
                    {this.state.user.Photo == 'rae' ?
                        <Row className="mt-5">

                            <Col className="mb-5 mb-xl-0" xl="12">
                                <Card className="shadow">
                                    <br />
                                    <span style={{ textAlign: 'center', float: 'center', fontSize: '23px', fontWeight: '700' }}>Ανά Χρήστη</span>
                                    <CardHeader className="border-0">

                                        <Row className="align-items-center">
                                            <div className="col">
                                                <span style={{ color: 'red' }} id="datepicker_error"></span>

                                                <HorizontalBar height="150" data={{
                                                    labels: ['ΔΕΗ', 'ELPEDISON', 'ΗΡΩΝ ΘΕΡΜΟΗΛΕΚΤΡΙΚΗ', 'ΜΥΤΙΛΗΝΑΙΟΣ Α.Ε. - OΜΙΛΟΣ ΕΠΙΧΕΙΡΗΣΕΩΝ  ', 'GREEK ENVIRONMENTAL & ENERGY NETWORK S.A.', 'VOLTERRA', 'WATT AND VOLT', 'NRG TRADING HOUSE', 'KEN ΠΑΡΑΓΩΓΗ ΚΑΙ ΕΜΠΟΡΙΑ ΕΝΕΡΓΕΙΑΚΩΝ ΠΡΟΙΟΝΤΩΝ', 'VOLTON ΕΛΛΗΝΙΚΗ ΕΝΕΡΓΕΙΑΚΗ', '(ΕΛ.ΤΑ.) ΕΛΛΗΝΙΚΑ ΤΑΧΥΔΡΟΜΕΙΑ', 'ΟΤΕ ΑΚΙΝΗΤΑ ΑΝΩΝΥΜΗ ΕΤΑΙΡΙΑ', 'ΕΤΑΙΡΕΙΑ ΠΡΟΜΗΘΕΙΑΣ ΑΕΡΙΟΥ ΘΕΣΣΑΛΟΝΙΚΗΣ - ΘΕΣΣΑΛΙΑΣ ΑΕ (ZENITH)', 'ΦΥΣΙΚΟ ΑΕΡΙΟ- ΕΛΛΗΝΙΚΗ ΕΤΑΙΡΙΑ ΕΝΕΡΓΕΙΑΣ', 'WE ENERGY', 'ENEL GREEN POWER HELLAS ΠΡΟΜΗΘΕΙΑ', 'ΘΩΜΑΣ ΣΟΥΜΠΑΣΗΣ ΜΕΠΕ', 'ΕΛΙΝΟΙΛ ΕΛΛΗΝΙΚΗ ΕΤΑΙΡΕΙΑ ΠΕΤΡΕΛΑΙΩΝ', 'ΕΦΑ ΕΝΕΡΓΕΙΑΚΗ ΕΤΑΙΡΕΙΑ ΦΥΣΙΚΟΥ ΑΕΡΙΟΥ', 'ΔΕΠΑ', 'ΔΕΔΔΗΕ', 'Εταιρεία Διανομής Αερίου Αττική (ΕΔΑ) Μ.Α.Ε.'],
                                                    datasets: [
                                                        {
                                                            label: 'Ανά Χρήστη',
                                                            title: {
                                                                display: false,
                                                                text: 'Ανά είδος δραστηριότητας',
                                                                fontSize: 20
                                                            },
                                                            data: this.state.data_statsbyusers,
                                                            backgroundColor: [
                                                                'rgba(255, 99, 132, 0.2)',
                                                                'rgba(54, 162, 235, 0.2)',
                                                                'rgba(255, 206, 86, 0.2)',
                                                                'rgba(75, 192, 192, 0.2)',
                                                                'rgba(153, 102, 255, 0.2)',
                                                                'rgba(255, 159, 64, 0.2)', 'rgba(255, 99, 132, 0.2)',
                                                                'rgba(54, 162, 235, 0.2)',
                                                                'rgba(255, 206, 86, 0.2)',
                                                                'rgba(75, 192, 192, 0.2)',
                                                                'rgba(153, 102, 255, 0.2)',
                                                                'rgba(255, 159, 64, 0.2)', 'rgba(255, 206, 86, 0.2)',
                                                                'rgba(75, 192, 192, 0.2)',
                                                                'rgba(153, 102, 255, 0.2)',
                                                                'rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)',
                                                                'rgba(153, 102, 255, 0.2)',
                                                                'rgba(255, 159, 64, 0.2)',
                                                            ],
                                                            borderColor: [
                                                                'rgba(255, 99, 132, 1)',
                                                                'rgba(54, 162, 235, 1)',
                                                                'rgba(255, 206, 86, 1)',
                                                                'rgba(75, 192, 192, 1)',
                                                                'rgba(153, 102, 255, 1)',
                                                                'rgba(255, 159, 64, 1)',
                                                                'rgba(255, 206, 86, 0.2)',
                                                                'rgba(75, 192, 192, 0.2)',
                                                                'rgba(153, 102, 255, 0.2)',
                                                                'rgba(255, 159, 64, 0.2)',
                                                                'rgba(75, 192, 192, 0.2)',
                                                                'rgba(153, 102, 255, 0.2)',
                                                                'rgba(255, 159, 64, 0.2)',
                                                            ],
                                                            borderWidth: 1,
                                                        },
                                                    ],
                                                }} options={{
                                                    indexAxis: 'x',
                                                    legend: {
                                                        display: false
                                                    },
                                                    // Elements options apply to all of the options unless overridden in a dataset
                                                    // In this case, we are setting the border of each horizontal bar to be 2px wide
                                                    elements: {
                                                        bar: {
                                                            borderWidth: 2,
                                                        },
                                                    },
                                                    responsive: true,
                                                    plugins: {
                                                        legend: {
                                                            position: 'left',
                                                        },
                                                        title: {
                                                            display: true,
                                                            text: 'Chart.js Horizontal Bar Chart',
                                                        },
                                                    },
                                                }} />
                                                {/* <Line height="30"
                                                width="100%"
                                                id="LineChart"
                                                data={state}
                                                options={{
                                                    backgroundColor: "red",
                                                    title: {
                                                        display: true,
                                                        text: 'Ανά χρονική περίοδο',
                                                        fontSize: 20,
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: 'right'
                                                    }

                                                }}

                                            /> */}

                                            </div>
                                            {/* <div className="col text-right"> */}
                                            {/* <Bar height="150" data={data} options={optionss} /> */}
                                            {/* </div> */}
                                        </Row>
                                    </CardHeader>

                                </Card>
                            </Col>
                        </Row> : <></>}
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">

                                        {this.state.user.Photo == 'rae' ?
                                            <React.Fragment>
                                                <div className="col">
                                                    <Pie height="150"
                                                        data={{
                                                            labels: ['Απαντημένα', 'Σε εκκρεμότητα', 'Απορριφθέντα'],
                                                            datasets: [
                                                                {
                                                                    label: 'Rainfall',
                                                                    backgroundColor: [
                                                                        '#0076de',
                                                                        '#ffb300',
                                                                        '#eb4034'
                                                                    ],
                                                                    hoverBackgroundColor: [
                                                                        '#0047ab',
                                                                        '#dba800',
                                                                        '#eb4034'
                                                                    ],
                                                                    data: this.state.data_statsbystate
                                                                    // data: [10, 30, 20]
                                                                }
                                                            ]
                                                        }
                                                        }
                                                        options={{
                                                            title: {
                                                                display: true,
                                                                text: 'Ανά κατάσταση αιτημάτων',
                                                                fontSize: 20
                                                            },
                                                            tooltips: {
                                                                callbacks: {
                                                                    label: function (tooltipItem, data) {
                                                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                                                        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                                        var total = meta.total;
                                                                        var currentValue = dataset.data[tooltipItem.index];
                                                                        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                                        return currentValue + ' (' + percentage + '%)';
                                                                    },
                                                                    title: function (tooltipItem, data) {
                                                                        return data.labels[tooltipItem[0].index];
                                                                    }
                                                                }
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: 'right'
                                                            }
                                                        }}
                                                    />

                                                </div>
                                                <div className="col text-right">

                                                    <Doughnut height="150"
                                                        data={{
                                                            labels: ['Διαχειριστές', 'Προμηθευτές', 'Διαχειριστές & Προμηθευτές'],
                                                            datasets: [
                                                                {
                                                                    label: 'Rainfall',
                                                                    backgroundColor: [
                                                                        '#ed0000',
                                                                        '#00a329',
                                                                        '#2FDE00',
                                                                        '#00A6B4',
                                                                        '#6800B4'
                                                                    ],
                                                                    hoverBackgroundColor: [
                                                                        '#ed0000',
                                                                        '#00a329',
                                                                        '#175000',
                                                                        '#003350',
                                                                        '#35014F'
                                                                    ],
                                                                    // data: [65, 59]
                                                                    data: this.state.data_statsbyuserkind
                                                                }
                                                            ]
                                                        }}
                                                        options={{
                                                            title: {
                                                                display: true,
                                                                text: 'Ανά είδος δραστηριότητας',
                                                                fontSize: 20
                                                            },
                                                            tooltips: {
                                                                callbacks: {
                                                                    label: function (tooltipItem, data) {
                                                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                                                        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                                        var total = meta.total;
                                                                        var currentValue = dataset.data[tooltipItem.index];
                                                                        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                                        return currentValue + ' (' + percentage + '%)';
                                                                    },
                                                                    title: function (tooltipItem, data) {
                                                                        return data.labels[tooltipItem[0].index];
                                                                    }
                                                                }
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: 'right'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </React.Fragment>

                                            : <>
                                                <Pie height="80"
                                                    data={{
                                                        labels: ['Απαντημένα', 'Σε εκκρεμότητα', 'Απορριφθέντα'],
                                                        datasets: [
                                                            {
                                                                label: 'Rainfall',
                                                                backgroundColor: [
                                                                    '#0076de',
                                                                    '#ffb300',
                                                                    '#eb4034'
                                                                ],
                                                                hoverBackgroundColor: [
                                                                    '#0047ab',
                                                                    '#dba800',
                                                                    '#eb4034'
                                                                ],
                                                                data: this.state.data_statsbystate
                                                                // data: [10, 30, 20]
                                                            }
                                                        ]
                                                    }
                                                    }
                                                    options={{
                                                        title: {
                                                            display: true,
                                                            text: 'Ανά κατάσταση αιτημάτων',
                                                            fontSize: 20
                                                        },
                                                        tooltips: {
                                                            callbacks: {
                                                                label: function (tooltipItem, data) {
                                                                    var dataset = data.datasets[tooltipItem.datasetIndex];
                                                                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                                    var total = meta.total;
                                                                    var currentValue = dataset.data[tooltipItem.index];
                                                                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                                    return currentValue + ' (' + percentage + '%)';
                                                                },
                                                                title: function (tooltipItem, data) {
                                                                    return data.labels[tooltipItem[0].index];
                                                                }
                                                            }
                                                        },
                                                        legend: {
                                                            display: true,
                                                            position: 'right'
                                                        }
                                                    }}
                                                />

                                            </>}
                                    </Row>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <Pie height="150"
                                                data={{
                                                    labels: ['Ηλεκτρισμός', 'Φυσικό Αέριο', 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο'],
                                                    datasets: [
                                                        {
                                                            label: 'Rainfall',
                                                            backgroundColor: [
                                                                '#0076de',
                                                                '#ffb300',
                                                                'rgba(75, 192, 192, 0.2)'
                                                            ],
                                                            hoverBackgroundColor: [
                                                                '#0047ab',
                                                                '#dba800',
                                                                'rgba(75, 192, 192, 0.2)'
                                                            ],
                                                            data: this.state.data_statsbyenergykind
                                                            // data: [60, 40]
                                                        }
                                                    ]

                                                }}
                                                options={{
                                                    title: {
                                                        display: true,
                                                        text: 'Ανά είδος ενέργειας',
                                                        fontSize: 20
                                                    },
                                                    tooltips: {
                                                        callbacks: {
                                                            label: function (tooltipItem, data) {
                                                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                                                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                                var total = meta.total;
                                                                var currentValue = dataset.data[tooltipItem.index];
                                                                var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                                return currentValue + ' (' + percentage + '%)';
                                                            },
                                                            title: function (tooltipItem, data) {
                                                                return data.labels[tooltipItem[0].index];
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: 'right'
                                                    }
                                                }}
                                            />

                                        </div>
                                        <div className="col text-center">
                                            <span style={{ textAlign: 'center', fontSize: '20px', fontWeight: '700', marginBottom: '30px' }}>Ανά κατηγορία αιτημάτων</span>
                                            <HorizontalBar height="200" data={{
                                                labels: ['Αλλαγή Προμηθευτή', 'Στοιχεία', 'Λογαριασμός', 'Εξυπηρέτηση', 'Πληρωμές', 'Καταγγελία Σύμβασης', 'Σύναψη Σύμβασης', 'Άλλο'],
                                                datasets: [
                                                    {
                                                        label: 'Είδος Αιτήματος',
                                                        title: {
                                                            display: true,
                                                            text: 'Ανά είδος δραστηριότητας',
                                                            fontSize: 20
                                                        },
                                                        // data: [12, 19, 3, 5, 20, 3, 8],
                                                        data: this.state.data_statsbycategory,
                                                        backgroundColor: [
                                                            'rgba(255, 99, 132, 0.2)',
                                                            'rgba(54, 162, 235, 0.2)',
                                                            'rgba(255, 206, 86, 0.2)',
                                                            'rgba(75, 192, 192, 0.2)',
                                                            'rgba(153, 102, 255, 0.2)',
                                                            'rgba(75, 192, 192, 0.2)',
                                                            'rgba(54, 162, 235, 0.2)',
                                                            'rgba(255, 99, 132, 0.2)',
                                                        ],
                                                        borderColor: [
                                                            'rgba(255, 99, 132, 1)',
                                                            'rgba(54, 162, 235, 1)',
                                                            'rgba(255, 206, 86, 1)',
                                                            'rgba(75, 192, 192, 1)',
                                                            'rgba(153, 102, 255, 1)',
                                                            'rgba(75, 192, 192, 0.2)',
                                                            'rgba(54, 162, 235, 0.2)',
                                                            'rgba(255, 99, 132, 0.2)',
                                                        ],
                                                        borderWidth: 1,
                                                    },
                                                ],
                                            }} options={{
                                                indexAxis: 'x',
                                                legend: {
                                                    display: false
                                                },
                                                elements: {
                                                    bar: {
                                                        borderWidth: 2,
                                                    },
                                                },
                                                responsive: true,
                                                plugins: {
                                                    legend: {

                                                        position: 'left',
                                                    },
                                                    title: {
                                                        display: false,
                                                        text: 'Chart.js Horizontal Bar Chart',
                                                    },
                                                },
                                            }} />
                                        </div>
                                    </Row>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        {this.state.user.Photo == 'rae' ?
                                            <React.Fragment>
                                                {/* <div className="col"></div> */}

                                                {/* <div className="col">
                                                    <Pie height="150"
                                                        data={{
                                                            labels: ['Διαχειριστές', 'Προμηθευτές', 'Διαχειριστές & Προμηθευτές'],
                                                            datasets: [
                                                                {
                                                                    label: 'Rainfall',
                                                                    backgroundColor: [
                                                                        '#0076de',
                                                                        '#ffb300',
                                                                    ],
                                                                    hoverBackgroundColor: [
                                                                        '#0047ab',
                                                                        '#dba800',
                                                                    ],
                                                                    data: this.state.data_statsbyuserkind
                                                                    // data: [30, 70]
                                                                }
                                                            ]

                                                        }}
                                                        options={{
                                                            title: {
                                                                display: true,
                                                                text: 'Ανά είδος Χρήστη',
                                                                fontSize: 20
                                                            },
                                                            tooltips: {
                                                                callbacks: {
                                                                    label: function (tooltipItem, data) {
                                                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                                                        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                                        var total = meta.total;
                                                                        var currentValue = dataset.data[tooltipItem.index];
                                                                        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                                        return currentValue + ' (' + percentage + '%)';
                                                                    },
                                                                    title: function (tooltipItem, data) {
                                                                        return data.labels[tooltipItem[0].index];
                                                                    }
                                                                }
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: 'right'
                                                            }
                                                        }}
                                                    />
                                                </div> */}

                                                <div className="col">
                                                    <Doughnut height="150"
                                                        data={{
                                                            labels: ['% των καταναλωτών αντέδρασαν όταν πήραν την τελική απάντηση', '% των καταναλωτών δεν αντέδρασαν όταν πήραν την τελική απάντηση'],
                                                            datasets: [
                                                                {
                                                                    label: 'Rainfall',
                                                                    backgroundColor: [
                                                                        '#ed0000',
                                                                        '#00a329',
                                                                        '#2FDE00',
                                                                        '#00A6B4',
                                                                        '#6800B4'
                                                                    ],
                                                                    hoverBackgroundColor: [
                                                                        '#ed0000',
                                                                        '#00a329',
                                                                        '#175000',
                                                                        '#003350',
                                                                        '#35014F'
                                                                    ],
                                                                    // data: [65, 59]
                                                                    data: this.state.data_statsbyusersreplied
                                                                }
                                                            ]
                                                        }}
                                                        options={{
                                                            title: {
                                                                display: true,
                                                                text: 'Ανά βαθμό αποδοχής/ικανοποίησης από την απάντηση ',
                                                                fontSize: 20
                                                            },
                                                            tooltips: {
                                                                callbacks: {
                                                                    label: function (tooltipItem, data) {
                                                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                                                        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                                        var total = meta.total;
                                                                        var currentValue = dataset.data[tooltipItem.index];
                                                                        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                                        return currentValue + ' (' + percentage + '%)';
                                                                    },
                                                                    title: function (tooltipItem, data) {
                                                                        return data.labels[tooltipItem[0].index];
                                                                    }
                                                                }
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: 'right'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="col"></div>
                                            </React.Fragment>

                                            : <>
                                                <div className="col text-right">
                                                    <Doughnut height="80"
                                                        data={{
                                                            labels: ['% των καταναλωτών αντέδρασαν όταν πήραν την τελική απάντηση', '% των καταναλωτών δεν αντέδρασαν όταν πήραν την τελική απάντηση'],
                                                            datasets: [
                                                                {
                                                                    label: 'Rainfall',
                                                                    backgroundColor: [
                                                                        '#ed0000',
                                                                        '#00a329',
                                                                        '#2FDE00',
                                                                        '#00A6B4',
                                                                        '#6800B4'
                                                                    ],
                                                                    hoverBackgroundColor: [
                                                                        '#ed0000',
                                                                        '#00a329',
                                                                        '#175000',
                                                                        '#003350',
                                                                        '#35014F'
                                                                    ],
                                                                    // data: [65, 59]
                                                                    data: this.state.data_statsbyusersreplied
                                                                }
                                                            ]
                                                        }}
                                                        options={{
                                                            title: {
                                                                display: true,
                                                                text: 'Ανά βαθμό αποδοχής/ικανοποίησης από την απάντηση ',
                                                                fontSize: 20
                                                            },
                                                            tooltips: {
                                                                callbacks: {
                                                                    label: function (tooltipItem, data) {
                                                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                                                        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                                        var total = meta.total;
                                                                        var currentValue = dataset.data[tooltipItem.index];
                                                                        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                                        return currentValue + ' (' + percentage + '%)';
                                                                    },
                                                                    title: function (tooltipItem, data) {
                                                                        return data.labels[tooltipItem[0].index];
                                                                    }
                                                                }
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: 'right'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </>}

                                    </Row>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">

                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={this.handleChange}
                                                name="startDate"
                                                dateFormat="dd/MM/yyyy"
                                            />
                                            {this.getDates_call()}

                                            <DatePicker
                                                selected={this.state.otherDay}
                                                onChange={this.handleChange2}
                                                name="startDate"
                                                dateFormat="dd/MM/yyyy"
                                            />



                                            <span style={{ color: 'red' }} id="datepicker_error"></span>
                                            <Line height="30"
                                                width="100%"
                                                id="LineChart"
                                                data={state}
                                                options={{
                                                    backgroundColor: "red",
                                                    title: {
                                                        display: true,
                                                        text: 'Ανά χρονική περίοδο',
                                                        fontSize: 20,
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: 'right'
                                                    }

                                                }}

                                            />

                                        </div>
                                        {/* <div className="col text-right"> */}
                                        {/* <Bar height="150" data={data} options={optionss} /> */}
                                        {/* </div> */}
                                    </Row>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <Doughnut height="150"
                                                data={{
                                                    labels: ['1-15 Ημέρες', '16-30 Ημέρες', '30+ Ημέρες'],
                                                    datasets: [
                                                        {
                                                            label: 'Rainfall',
                                                            backgroundColor: [
                                                                '#0076de',
                                                                '#ffb300',
                                                                '#eb4034'
                                                            ],
                                                            hoverBackgroundColor: [
                                                                '#0047ab',
                                                                '#dba800',
                                                                '#eb4034'
                                                            ],
                                                            // data: [50, 20, 30]
                                                            data: this.state.data_statsbyusersrepliedduration
                                                        }
                                                    ]
                                                }}
                                                options={{
                                                    title: {
                                                        display: true,
                                                        text: 'Ανά χρονική διάρκεια πρώτης απάντησης',
                                                        fontSize: 20
                                                    },
                                                    tooltips: {
                                                        callbacks: {
                                                            label: function (tooltipItem, data) {
                                                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                                                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                                var total = meta.total;
                                                                var currentValue = dataset.data[tooltipItem.index];
                                                                var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                                return currentValue + ' (' + percentage + '%)';
                                                            },
                                                            title: function (tooltipItem, data) {
                                                                return data.labels[tooltipItem[0].index];
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: 'right'
                                                    }
                                                }}
                                            />

                                        </div>
                                        <div className="col text-right">
                                            <Doughnut height="150"
                                                data={{
                                                    labels: ['έως 2 εβδομάδες', '3-4 εβδομάδες', 'περισσότερο από ένα μήνα'],
                                                    datasets: [
                                                        {
                                                            label: 'Rainfall',
                                                            backgroundColor: [
                                                                '#0076de',
                                                                '#ffb300',
                                                                '#eb4034'
                                                            ],
                                                            hoverBackgroundColor: [
                                                                '#0047ab',
                                                                '#dba800',
                                                                '#eb4034'
                                                            ],
                                                            data: this.state.data_statsbypendingduration
                                                            // data: [40, 10, 5]
                                                        }
                                                    ]
                                                }}
                                                options={{
                                                    title: {
                                                        display: true,
                                                        text: 'Ανά χρονική διάρκεια που βρίσκονται σε εκκρεμότητα',
                                                        fontSize: 20
                                                    },
                                                    tooltips: {
                                                        callbacks: {
                                                            label: function (tooltipItem, data) {
                                                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                                                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                                var total = meta.total;
                                                                var currentValue = dataset.data[tooltipItem.index];
                                                                var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                                return currentValue + ' (' + percentage + '%)';
                                                            },
                                                            title: function (tooltipItem, data) {
                                                                return data.labels[tooltipItem[0].index];
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: 'right'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Row>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row>

                    <div class='push'></div>
                </Container>

                {/* <div class="container" style={{ marginTop: '-200px', paddingTop: '100px', paddingBottom: '100px', backgroundColor: '#fff', zIndex: '100' }}>
                    <div class="row" style={{ marginBottom: '50px' }}>
                        <div class="col-sm">

                        </div>
                        <div class="col-sm">
                            <p>a</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <Line height="150"
                                data={state}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Average Rainfall per month',
                                        fontSize: 20
                                    },
                                    legend: {
                                        display: true,
                                        position: 'right'
                                    }
                                }}
                            />

                        </div>
                        <div class="col-sm">
                            <Bar height="150" data={data} options={optionss} />
                        </div>
                    </div>
                </div> */}





            </React.Fragment>
        );
    }


}

export default Statistics;