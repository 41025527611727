  import {
    get, getAsync,
    post, postAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
  } from "./apiCallerHelper.core";

  
  import {
    statsFilter,
  } from "./apiCallerHelper.Statistics"

  
  export const ApiCallerHelper = {
    hasInitialisedToken: () => {
      return hasInitialisedToken();
    },
    Common: {
      get,
      post,
      delete: del,
    },
    Statistics : {
      statsFilter: statsFilter,
    },
  };