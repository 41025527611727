import React from "react";
import { Breadcrumb } from 'rsuite';


import {
    Button,
    Collapse,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";

class newInstance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            workflows: []
        };

        this.createInstance = this.createInstance.bind(this);
    }

    createInstance() {
        const wId = document.getElementById('selected-workflow').value;

        window.location.replace("/admin/createinstance/" + wId);
    }

    componentDidMount() {
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/workflows/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            workflows: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                );
        };
    }

    render() {
        return (
            <>
                <InstanceHeader />
                <div className="header pb-4" style={{ background: "linear-gradient(to right, #FFFFFF, #4A002B)" }}>
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col lg="8" xl="12">
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                    </CardTitle>
                                                    <span className="h4 font-weight-bold mb-0">
                                                        <a href="admin/instances">Υποβολή Αιτήματος</a> / <a>Δημιουργία νέου αιτήματος : Επιλογή Προτύπου</a>
                                                    </span>
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <Container className="mt--4" fluid>
                    <Row className="mt-5">
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Δημιουργία νέου παραπόνου</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            Επιλογη προτυπου παραπόνου
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Πρότυπο παραπόνου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue="none"
                                                            id="selected-workflow"
                                                            placeholder="First name"
                                                            type="select"
                                                        >
                                                            {this.state.workflows.map((dynamicWorkflows) =>
                                                                <option value={dynamicWorkflows.Id}>{dynamicWorkflows.Name}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <Button
                                                        color="primary"
                                                        onClick={this.createInstance}
                                                        title="Επιλογή"
                                                    >
                                                        Επιλογή <i className="fas fa-tick" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default newInstance;
