import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import Moment from "moment";
import 'moment/locale/el';

class Workflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      submitted: 0,
      unSubmitted: 0,
      workflow: [],
      roles: []
    };
  }

  componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/count/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              submitted: result.submitted,
              unSubmitted: result.nonSubmitted
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              roles: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflows/" + handle + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              workflow: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
    };
  }

  render() {
    return (
      <>
        <div className="header pb-8 pt-3 pt-md-7" style={{ background: "linear-gradient(to right, #FFFFFF, #4A002B)" }}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="8" xl="6">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Υποβεβλημενες ροες
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.submitted}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Τον τελευταίο μήνα</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="8" xl="6">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Μη Υποβεβλημενες ροες
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{this.state.unSubmitted}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" /> 1.10%
                        </span>{" "}
                        <span className="text-nowrap">Τον τελευταίο μήνα</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{this.state.workflow.name}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Αποθήκευση αλλαγών
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Βασικα στοιχεια προγραμματος
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Όνομα προγράμματος
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.workflow.name}
                              id="input-username"
                              placeholder="Όνομα προγράμματος"
                              type="text"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Διάρκεια (ημέρες)
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.workflow.duration}
                              id="input-username"
                              placeholder="Διάρκεια προγράμματος"
                              type="number"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Προτεραιότητα
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.workflow.priority}
                              id="input-username"
                              placeholder="Προτεραιότητα"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Περιγραφή
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Περιγραφή προγράμματος"
                              rows="11"
                              defaultValue={this.state.workflow.description}
                              type="textarea"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Ημερομηνία Έναρξης
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.workflow.startingDate}
                              id="input-first-name"
                              placeholder="First name"
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Ημερομηνία Λήξης
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.workflow.endingDate}
                              id="input-last-name"
                              placeholder="Last name"
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Διαχειριστής
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Lucky"
                              id="input-first-name"
                              placeholder="First name"
                              type="select"
                            >
                              {this.state.roles.map((dynamicRoles) =>
                                <option>{dynamicRoles}</option>
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Συναρμόδιοι
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Lucky"
                              id="input-first-name"
                              placeholder="First name"
                              type="select"
                              multiple
                            >
                              {this.state.roles.map((dynamicRoles) =>
                                <option>{dynamicRoles}</option>
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>


        <Container className="mt-2" fluid>
          <Row className="mt-5">
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Προβολή βημάτων προγράμματος</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href={"/admin/steps/" + this.props.match.params.id}
                        size="sm"
                      >
                        Προβολή
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Workflow;
