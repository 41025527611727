import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Input,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import WorkflowStepsHeader from "components/Headers/WorkflowStepsHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';

class StepActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            activeNav: 1,
            isLoaded: false,
            step: [],
            actions: []
        };
    }

    componentDidMount() {
        const handle = this.props.match.params.id;
        fetch(process.env.REACT_APP_API_LINK + "/api/workflows/step/" + handle + '/', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        step: result,
                        actions: result.actions,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    render() {
        return (
            <>
                <WorkflowStepsHeader />
                <div className="header pb-4" style={{ background: "linear-gradient(to right, #FFFFFF, #4A002B)" }}>
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col lg="8" xl="12">
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {this.state.step.name}
                                                    </span>
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <Container className="mt-2" fluid>
                    <Row className="mt-5">
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Πεδία βήματος: {this.state.step.name}</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="success"
                                                href="#pablo"
                                                onClick={e => e.preventDefault()}
                                                size="sm"
                                            >
                                                Προσθήκη νέας ενέργειας
                                            </Button>
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                onClick={e => e.preventDefault()}
                                                size="sm"
                                            >
                                                Αποθήκευση αλλαγών
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <div className="pl-lg-4">
                                            <Row>
                                                {this.state.actions.sort((a, b) => a.order - b.order).map((dynamicActions) =>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                {dynamicActions.name}
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue=""
                                                                id="input-username"
                                                                placeholder={dynamicActions.description}
                                                                type="text"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default StepActions;
