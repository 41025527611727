import { Steps, Panel, Notification, Timeline, Paragraph } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { saveAs } from 'file-saver';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

import 'antd/dist/antd.css';

import React, { useState } from "react";

import {
  Collapse,
  Card,
  Form,
  FormGroup,
  Input,
  Table,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

import ReactTooltip from 'react-tooltip';

import InstanceHeader from "components/Headers/InstanceHeader.js";
import { CommonLoading } from 'react-loadingg';
import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import background from "../../assets/img/header-background.jpg";

import axios from 'axios';

class InstanceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assigned_names: [],
      assignedId: null,
      userAssignedId: null,
      showModal: false,
      showSecondModal: false,
      showThirdModal: false,
      showFourthModal: false,
      isOpen: false,
      isSecondOpen: false,
      isThirdOpen: false,
      isFourthOpen: false,
      isFifthOpen: false,
      error: null,
      step: 0,
      notes: [],
      details: [],
      instance: [],
      workflow: [],
      fileUpload: null,
      steps: [],
      user: [],
      users: [],
      files: [],
      consumerFiles: [],
      receiverFiles: [],
      enomosFiles: [],
      replies: [],
      repliesEnomos: [],
      extra: [],
      enomosExtra: [],
      answerStep: '',
      answerAction: '',
      answerToConsumer: '',
      answerToCompany: '',
      files: [],
      filesEnomos: [],
      consumerEnomosFiles: [],
      receiverEnomosFiles: [],
      lawyerToConsumerEnomosFiles: [],
      lawyerToReceiverEnomosFiles: [],
      showAnswer: 0,
      hasReplies: false,
      hasRepliesEnomos: false,
      loading: true,
      comment: '',
      commentToConsumerDisabled: true,
      commentToCompanyDisabled: true,
      file: null,
      declineMessage: '',
      declineDate: '',
      userWhoDeclined: ''
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem('token')) {
      const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/enomos/details/" + handle + "/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          this.setState({
            instance: res.data,
            details: res.data
          });
          this.setState({
            step: this.state.instance.Status
          });
        });

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/extra/" + handle + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              extra: result,
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/enomos/extra/" + handle + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              enomosExtra: result,
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              user: result,
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/user/enomos/get_users_by_role/" + handle + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              users: result,
            });
            console.log(this.state.users['Lawyers']);
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/replies/all/" + handle + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              replies: result.replies,
              hasReplies: result.hasReplies
            });

            if (result.hasReplies) {
              var counter = 0;
              result.replies.forEach(function (arrayItem) {
                arrayItem.user = result.users[counter];
                counter++;

              });
              this.setState({
                replies: result.replies,
              });

            }
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/replies/enomos/all/" + handle + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              repliesEnomos: result.replies,
              hasRepliesEnomos: result.hasReplies
            });

            if (result.hasReplies) {
              var counter = 0;
              result.replies.forEach(function (arrayItem) {
                arrayItem.user = result.users[counter];
                counter++;

              });
              this.setState({
                repliesEnomos: result.replies,
              });

            }
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/files/instance/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              files: result,
              loading: false
            });
            let allFiles = this.state.files
            let filteredConsumerFiles = allFiles.filter(element => {
              if (element.UserId == this.state.instance.UserId) return element
            })
            let filteredReceiverFiles = allFiles.filter(element => {
              if (element.UserId != this.state.instance.UserId) return element
            })
            this.setState({
              consumerFiles: filteredConsumerFiles,
              receiverFiles: filteredReceiverFiles
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/enomos/get_assigned_names/" + handle + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              assigned_names: result,
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/files/enomos/instance/" + this.state.instance.Id + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              filesEnomos: result,
              loading: false
            });
            let allEnomosFiles = this.state.filesEnomos
            let filteredConsumerEnomosFiles = allEnomosFiles.filter(element => {
              if (element.UserType == 0) return element
            })
            let filteredReceiverEnomosFiles = allEnomosFiles.filter(element => {
              if (element.UserType == 2) return element
            })
            let filteredLawyerToConsumerEnomosFiles = allEnomosFiles.filter(element => {
              if (element.UserType == 1 && element.ReceiverType == 0) return element
            })
            let filteredLawyerToReceiverEnomosFiles = allEnomosFiles.filter(element => {
              if (element.UserType == 1 && element.ReceiverType == 2) return element
            })
            this.setState({
              consumerEnomosFiles: filteredConsumerEnomosFiles,
              receiverEnomosFiles: filteredReceiverEnomosFiles,
              lawyerToConsumerEnomosFiles: filteredLawyerToConsumerEnomosFiles,
              lawyerToReceiverEnomosFiles: filteredLawyerToReceiverEnomosFiles,
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )
    };
    const result = axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/lists/", {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
      .then(
        (res) => {

          let all = res.data.all
          let filteredAllSuppliers = []
          all.forEach(function (element, index) {
            filteredAllSuppliers.push({
              value: element.Name,
              label: element.DisplayName
            });
          });
          // var filteredALL = filteredAllSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')
          let uniqueAll = [...new Set(filteredAllSuppliers.map(({ value }) => value))].map(e => filteredAllSuppliers.find(({ value }) => value == e));

          this.setState({
            allSuppliers: uniqueAll
          });
          console.log(this.state.allSuppliers);
        },
        (error) => {
          this.setState({
            error
          });
        }
      )

    this.fetchNotes();
  }

  handleShowModal = () => {
    this.setState({ showFourthModal: true });
  }

  handleJustCloseModal = () => {
    this.setState({ showFourthModal: false });
  }

  handleMessageChange = (e) => {
    this.setState({ declineMessage: e.target.value });
  }

  declineInstance = () => {

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/enomos/decline/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }),
        body: JSON.stringify({
          'InstanceId': this.state.instance.Id,
          'Message': this.state.declineMessage
        })
      })
        .then(data => {
          window.location.replace("/admin/details/" + this.state.instance.Id + "/");
        })
    }
  }

  fetchNotes = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_LINK + `/api/workflowinstances/enomos/notes/${this.state.instance.Id}`);
      if (response.ok) {
        const notes = await response.json();
        this.setState({ notes });
      } else {
        console.error('Failed to fetch notes');
      }
    } catch (error) {
      console.error('An error occurred while fetching notes', error);
    }
  };

  renderNotes = () => {
    return this.state.notes.map((note, index) => (
      <div key={index}>
        <strong>{new Date(note.DateCreated).toLocaleString()}:</strong> {note.Message}
      </div>
    ));
  };

  downloadAllFiles = (files) => {
    files.forEach(f => {
      fetch(f.Link, {
        headers: {
          'Content-Type': f.Type
        }
      })
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, f.FileName);
        });
    })
  };

  handleDownload = (link, contentType, name) => {
    fetch(link, {
      headers: {
        'Content-Type': contentType
      }
    })
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, name);
      });
  };

  GiveAnswer = stepStatus => {
    this.setState({
      showAnswer: 1
    });
  }

  updateAnswerToConsumer(evt) {
    if (evt.target.value != '') {
      this.setState({ commentToConsumerDisabled: false })
    }
    else this.setState({ commentToConsumerDisabled: true })

    this.setState({
      answerToConsumer: evt.target.value
    })
  }

  updateAnswerToCompany(evt) {
    if (evt.target.value != '') {
      this.setState({ commentToCompanyDisabled: false })
    }
    else this.setState({ commentToCompanyDisabled: true })

    this.setState({
      answerToCompany: evt.target.value
    })
  }

  sendAnswer(receivertype, comment) {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/replies/enomos/new/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }),
        body: JSON.stringify({
          'InstanceId': this.state.instance.Id,
          'UserType': 1,
          'Message': comment,
          'ReceiverType': receivertype
        })
      })
        .then(data => {
          fetch(process.env.REACT_APP_API_LINK + "/api/replies/enomos/all/" + this.state.instance.Id + "/", {
            method: 'get',
            headers: new Headers({
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/x-www-form-urlencoded'
            })
          })
            .then(res => res.json())
            .then(
              (result) => {
                this.setState({
                  repliesEnomos: result.replies,
                  hasRepliesEnomos: true,
                  showAnswer: 0
                });

                var counter = 0;
                result.replies.forEach(function (arrayItem) {
                  arrayItem.user = result.users[counter];
                  counter++;

                });
                this.setState({
                  repliesEnomos: result.replies,
                });
                window.location.reload(false);

              },
              (error) => {
                this.setState({
                  error
                });
              }
            )
        })
    }
  }

  updateFile(evt) {
    this.setState({ fileUpload: evt.target.files[0] });
  }

  deleteFile = fileId => {
    if (window.confirm("Είστε σίγουροι ότι θέλετε να διαγράψετε το συγκεκριμένο αρχείο;") === true) {
      if (localStorage.getItem('token')) {
        axios.delete(process.env.REACT_APP_API_LINK + '/api/files/enomos/' + fileId + "/", {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
          .then(res => {
            axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
              .then(res => {
                this.setState({
                  loading: false,
                  files: res.data
                });
                window.location.reload(false)
              })
          })
      }
    }
    else { }
  }

  uploadFile(evt, UserType, ReceiverType) {
    const formData = new FormData();

    // Update the formData object 
    formData.append(
      "files",
      evt.target.files[0]
    );



    fetch(
      process.env.REACT_APP_FILEMANAGER_LINK,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {

        fetch(process.env.REACT_APP_API_LINK + '/api/files/enomos/new/', {
          method: 'POST',
          headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }),
          body: JSON.stringify({
            'Name': result[0].name,
            'Path': result[0].path,
            'Size': result[0].size,
            'Type': result[0].type,
            'InstanceId': this.state.instance.Id,
            'StepId': '674d15b5-2717-40c2-b119-489572b8501a',
            'ActionId': '3afe3b80-db44-4407-93c8-c17dde55221b',
            'UserType': UserType,
            'ReceiverType': ReceiverType
          })
        })
          .then(data => {

            axios.get(process.env.REACT_APP_API_LINK + "/api/files/enomos/status/" + this.state.instance.Id + "/", {
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
              .then(res => {
                this.setState({
                  loading: false,
                  filesEnomos: res.data
                });
                let allEnomosFiles = this.state.filesEnomos
                let filteredConsumerEnomosFiles = allEnomosFiles.filter(element => {
                  if (element.UserType == 0) return element
                })
                let filteredReceiverEnomosFiles = allEnomosFiles.filter(element => {
                  if (element.UserType == 2) return element
                })
                let filteredLawyerToConsumerEnomosFiles = allEnomosFiles.filter(element => {
                  if (element.UserType == 1 && element.ReceiverType == 0) return element
                })
                let filteredLawyerToReceiverEnomosFiles = allEnomosFiles.filter(element => {
                  if (element.UserType == 1 && element.ReceiverType == 2) return element
                })
                this.setState({
                  consumerEnomosFiles: filteredConsumerEnomosFiles,
                  receiverEnomosFiles: filteredReceiverEnomosFiles,
                  lawyerToConsumerEnomosFiles: filteredLawyerToConsumerEnomosFiles,
                  lawyerToReceiverEnomosFiles: filteredLawyerToReceiverEnomosFiles,
                });
              })

            fetch(process.env.REACT_APP_API_LINK + "/api/files/enomos/instance/" + this.state.instance.Id + '/', {
              method: 'get',
              headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
              })
            })
              .then(res => res.json())
              .then(
                (result) => {
                  this.setState({
                    filesEnomos: result,
                    loading: false
                  });
                  let allEnomosFiles = this.state.filesEnomos
                  let filteredConsumerEnomosFiles = allEnomosFiles.filter(element => {
                    if (element.UserType == 0) return element
                  })
                  let filteredReceiverEnomosFiles = allEnomosFiles.filter(element => {
                    if (element.UserType == 2) return element
                  })
                  let filteredLawyerToConsumerEnomosFiles = allEnomosFiles.filter(element => {
                    if (element.UserType == 1 && element.ReceiverType == 0) return element
                  })
                  let filteredLawyerToReceiverEnomosFiles = allEnomosFiles.filter(element => {
                    if (element.UserType == 1 && element.ReceiverType == 2) return element
                  })
                  this.setState({
                    consumerEnomosFiles: filteredConsumerEnomosFiles,
                    receiverEnomosFiles: filteredReceiverEnomosFiles,
                    lawyerToConsumerEnomosFiles: filteredLawyerToConsumerEnomosFiles,
                    lawyerToReceiverEnomosFiles: filteredLawyerToReceiverEnomosFiles,
                  });
                },
                (error) => {
                  this.setState({
                    error
                  });
                }
              )
          })
      });
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen, // Toggle the isOpen state
    }));
  };

  toggleSecondCollapse = () => {
    this.setState((prevState) => ({
      isSecondOpen: !prevState.isSecondOpen, // Toggle the isOpen state
    }));
  };

  toggleThirdCollapse = () => {
    this.setState((prevState) => ({
      isThirdOpen: !prevState.isThirdOpen, // Toggle the isOpen state
    }));
  };

  toggleFourthCollapse = () => {
    this.setState((prevState) => ({
      isFourthOpen: !prevState.isFourthOpen, // Toggle the isOpen state
    }));
  };

  toggleFifthCollapse = () => {
    this.setState((prevState) => ({
      isFifthOpen: !prevState.isFifthOpen, // Toggle the isOpen state
    }));
  };

  setShowModal = () => {
    this.setState({ showModal: !this.state.showModal });
  }

  setShowSecondModal = () => {
    this.setState({ showSecondModal: !this.state.showSecondModal });
  }

  setShowThirdModal = () => {
    this.setState({ showThirdModal: !this.state.showThirdModal });
  }

  updateLawyerAssignedId = (evt) => {
    // Assuming the value passed is the lawyer Id
    this.setState({ assignedId: evt.value }, () => {
      console.log('Assigned Lawyer Id:', this.state.assignedId);
    });
  };

  updateUserAssignedId = (evt) => {
    // Assuming the value passed is the lawyer Id
    this.setState({ userAssignedId: evt.value }, () => {
      console.log('Assigned Lawyer Id:', this.state.userAssignedId);
    });
  };

  assignInstanceToLawyer = () => {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/enomos/' + this.state.instance.Id + '/assign_to_lawyer/' + this.state.assignedId + '/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(data => {
          window.location.reload(false);
        })
    }
  }

  assignInstance = () => {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/enomos/' + this.state.instance.Id + '/assign/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(data => {
          window.location.reload(false);
        })
    }
  }

  shareDetails = () => {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/enomos/' + this.state.instance.Id + '/share_details/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(data => {
          window.location.reload(false);
        })
    }
  }

  assignInstanceToCompany = () => {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/enomos/' + this.state.instance.Id + '/assign_to_company/' + this.state.userAssignedId + '/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(data => {
          window.location.reload(false);
        })
    }
  }

  changeInstanceStatus = (status) => {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/enomos/' + this.state.instance.Id + '/change_status/' + status + '/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(data => {
          window.location.reload(false);
        })
    }
  }

  updateComment = (e) => {
    this.setState({ comment: e.target.value });
  };

  newNote = () => {

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/enomos/new_note/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }),
        body: JSON.stringify({
          'InstanceId': this.state.instance.Id,
          'Message': this.state.comment
        })
      })
        .then(data => {
          window.location.replace("/admin/details/" + this.state.instance.Id + "/");
        })
    }
  }

  render() {
    const { showFourthModal, declineMessage } = this.state;
    const lawyerOptions = this.state.users?.['Lawyers']?.map((lawyer) => {
      return { value: lawyer.id, label: lawyer.name };
    }) || [];

    const userOptions = this.state.users?.['HeadUsersAndUsers']?.map((lawyer) => {
      return { value: lawyer.id, label: lawyer.name };
    }) || [];

    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      )
    }
    else {
      return (
        <>
          <InstanceHeader />
          <div
            className='header pb-4'
            style={{ backgroundImage: `url(${background})` }}
          >
            <Container fluid>
              <div className='header-body'>
                <Row>
                  <Col lg='8' xl='12'>
                    <Card className='card-stats mb-4 mb-xl-0'>
                      <CardBody>
                        <Row>
                          <div className='col'>
                            <CardTitle
                              tag='h5'
                              className='text-uppercase text-muted mb-0'
                            ></CardTitle>
                            <span
                              className='h2 font-weight-bold mb-0'
                              style={{ paddingLeft: "15px" }}
                            >
                              {this.state.instance.Status === 2
                                ? "Ολοκληρωση Αιτηματος"
                                : "Επεξεργασία Αιτήματος"}
                            </span>

                            {(this.state.user.PersonRole == "Admin" ||
                              this.state.user.PersonRole == "Lawyer") &&
                            this.state.instance.Status == 1 ? (
                              <>
                                <Col className='text-left' xs='12'>
                                  <br />
                                  {!this.state.instance.CompanyAssigned &&
                                  this.state.instance.LawyerAssignedId ===
                                    this.state.user.Id ? (
                                    <Button
                                      color='info'
                                      onClick={() => {
                                        this.assignInstance()
                                      }}
                                      size='sm'
                                    >
                                      Ανάθεση σε Εταιρεία
                                    </Button>
                                  ) : null}
                                  {this.state.instance.CompanyAssigned !=
                                    null &&
                                  !this.state.instance.DetailsShared ? (
                                    <Button
                                      color='warning'
                                      onClick={() => {
                                        this.shareDetails()
                                      }}
                                      size='sm'
                                    >
                                      Κοινοποίηση στοιχείων
                                    </Button>
                                  ) : null}
                                  {!this.state.instance.CompanyAssigned ? (
                                    <Button
                                      color='danger'
                                      onClick={this.handleShowModal}
                                      size='sm'
                                    >
                                      Απευθείας απόρριψη
                                    </Button>
                                  ) : null}

                                  <Button
                                    color='success'
                                    size='sm'
                                    onClick={this.setShowModal}
                                  >
                                    {this.state.instance.LawyerAssignedId
                                      ? "Αλλαγή Διαμεσολαβητή"
                                      : "Ανάθεση σε Διαμεσολαβητή"}
                                  </Button>
                                  {this.state.user.PersonRole == "Head User" &&
                                  this.state.instance.Status == 1 ? (
                                    <>
                                      <Button
                                        color='primary'
                                        size='sm'
                                        onClick={this.setShowSecondModal}
                                      >
                                        {this.state.instance.UserAssignedId
                                          ? "Αλλαγή Εκπρόσωπου Εταιρείας"
                                          : "Ορισμός Εκπρόσωπου Εταιρείας"}
                                      </Button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </>
                            ) : (
                              <></>
                            )}
                            <hr />
                            <CardBody>
                              <Row className='align-items-center'>
                                <span style={{ fontWeight: "bold" }}>
                                  Αριθμός Αιτήματος:{" "}
                                </span>{" "}
                                &nbsp; {this.state.instance.SerialNumber}
                              </Row>
                              <Row className='align-items-center'>
                                <span style={{ fontWeight: "bold" }}>
                                  Ημερομηνία Έναρξης:{" "}
                                </span>{" "}
                                &nbsp;{" "}
                                {Moment(this.state.instance.StartedAt)
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                              </Row>
                              <Row className='align-items-center'>
                                {this.state.instance.DateAssigned ? (
                                  <>
                                    <span style={{ fontWeight: "bold" }}>
                                      Ημερομηνία Ανάθεσης:{" "}
                                    </span>
                                    &nbsp;
                                    {Moment(this.state.instance.DateAssigned)
                                      .locale("el")
                                      .format("dddd, DD MMM YYYY")}
                                  </>
                                ) : null}
                              </Row>

                              <Row className='align-items-center'>
                                <span style={{ fontWeight: "bold" }}>
                                  Διαμεσολαβητής:{" "}
                                </span>{" "}
                                &nbsp; {this.state.assigned_names[1]}
                              </Row>
                              <Row className='align-items-center'>
                                <span style={{ fontWeight: "bold" }}>
                                  Εκπρόσωπος Εταιρείας:{" "}
                                </span>{" "}
                                &nbsp; {this.state.assigned_names[0]}
                              </Row>
                            </CardBody>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <Container className='mt--4' fluid>
            <Row className='mt-5'>
              <Modal
                show={this.state.showFourthModal}
                onHide={this.handleJustCloseModal}
                dialogClassName='modal-90w'
                aria-labelledby='example-custom-modal-styling-title'
                style={{ opacity: 1 }}
              >
                <Modal.Header closeButton>
                  <Modal.Title id='example-custom-modal-styling-title'>
                    <strong style={{ fontSize: "18px", textAlign: "center" }}>
                      Απευθείας απόρριψη αιτήματος
                    </strong>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <p>
                      Μόλις επιλέξατε να απορρίψετε την αναφορά με Α/Α:{" "}
                      {this.state.instance.SerialNumber} Παρακαλούμε όπως γίνει
                      καταγραφή της αιτίας που οδήγησε στην απόρριψη της
                      αναφοράς, προς έλεγχο της Αρχής και ενημέρωση του
                      Καταναλωτή
                    </p>
                    <textarea
                      style={{ width: "450px" }}
                      value={declineMessage}
                      onChange={this.handleMessageChange}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    color='primary'
                    disabled={!declineMessage.trim()}
                    onClick={this.declineInstance}
                  >
                    Συνέχεια
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                show={this.state.showModal}
                onHide={this.setShowModal}
                dialogClassName='modal-90w'
                aria-labelledby='example-custom-modal-styling-title'
                style={{ opacity: 1 }}
              >
                <Modal.Header closeButton>
                  <Modal.Title id='example-custom-modal-styling-title'>
                    <strong style={{ fontSize: "18px", textAlign: "center" }}>
                      Ανάθεση Παραπόνου σε Διαμεσολαβητή
                    </strong>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <div className='pl-lg-12'>
                      <Row>
                        <Col xs={12} md={12}>
                          <Select
                            onChange={this.updateLawyerAssignedId}
                            options={lawyerOptions}
                            placeholder='Επιλέξτε Διαμεσολαβητή'
                          />
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    color='primary'
                    onClick={() => {
                      this.assignInstanceToLawyer()
                    }}
                    size='sm'
                  >
                    Ανάθεση
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                show={this.state.showSecondModal}
                onHide={this.setShowSecondModal}
                dialogClassName='modal-90w'
                aria-labelledby='example-custom-modal-styling-title'
                style={{ opacity: 1 }}
              >
                <Modal.Header closeButton>
                  <Modal.Title id='example-custom-modal-styling-title'>
                    <strong style={{ fontSize: "18px", textAlign: "center" }}>
                      Ορισμός Εκπρόσωπου
                    </strong>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <div className='pl-lg-12'>
                      <Row>
                        <Col xs={12} md={12}>
                          <Select
                            onChange={this.updateUserAssignedId}
                            options={userOptions}
                            placeholder='Επιλέξτε Εκπρόσωπο'
                          />
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    color='primary'
                    onClick={() => {
                      this.assignInstanceToCompany()
                    }}
                    size='sm'
                  >
                    Ολοκλήρωση
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                show={this.state.showThirdModal}
                onHide={this.setShowThirdModal}
                dialogClassName='modal-90w'
                aria-labelledby='example-custom-modal-styling-title'
                style={{ opacity: 1 }}
              >
                <Modal.Header closeButton>
                  <Modal.Title id='example-custom-modal-styling-title'>
                    <strong style={{ fontSize: "18px", textAlign: "center" }}>
                      Προσθήκη σημείωσης
                    </strong>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Row>
                      <Col xs={12} md={12}>
                        <Input
                          type='textarea'
                          rows={3}
                          placeholder='Αφήστε ένα σχόλιο...'
                          onChange={this.updateComment}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    color='primary'
                    onClick={() => {
                      this.newNote()
                    }}
                    size='sm'
                  >
                    Προσθήκη
                  </Button>
                </Modal.Footer>
              </Modal>

              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <Col xs='3'>
                        <h3 className='mb-0'>Πορεία Αιτήματος</h3>
                      </Col>
                      {this.state.instance.LawyerAssignedId ==
                        this.state.user.Id &&
                      this.state.instance.Status == 1 &&
                      this.state.instance.CompanyAssigned != null &&
                      this.state.user.Email == "admin@enomos.gr" ? (
                        <Col className='text-right' xs='9'>
                          <Button
                            color='info'
                            href={
                              "/admin/instancexport/" + this.state.instance.Id
                            }
                            size='sm'
                          >
                            Εξαγωγή Αιτήματος
                          </Button>
                          <Button
                            color='success'
                            onClick={() => {
                              this.changeInstanceStatus("success")
                            }}
                            size='sm'
                          >
                            Επιτυχής Διαμεσολάβηση
                          </Button>
                          <Button
                            color='danger'
                            onClick={() => {
                              this.changeInstanceStatus("failure")
                            }}
                            size='sm'
                          >
                            Ανεπιτυχής Διαμεσολάβηση
                          </Button>
                          {/* <Button
                            color="warning"
                            href={"/admin/instancexport/" + this.state.instance.Id}
                            size="sm"
                          >
                            Εξαγωγή Αιτήματος
                          </Button> */}
                        </Col>
                      ) : (
                        <>
                          <Col className='text-right' xs='9'>
                            <Button
                              color='info'
                              href={
                                "/admin/instancexport/" + this.state.instance.Id
                              }
                              size='sm'
                            >
                              Εξαγωγή Αιτήματος
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Steps current={this.state.step}>
                        <Steps.Item
                          title='Υποβολή αιτήματος'
                          description='Το αίτημα υποβλήθηκε.'
                        />
                        <Steps.Item
                          title='Επεξεργασία αιτήματος'
                          description='Το αίτημα επεξεργάζεται.'
                        />
                        <Steps.Item
                          title='Ολοκλήρωση αιτήματος'
                          description='Το αίτημα ολοκληρώθηκε.'
                        />
                      </Steps>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className='mt-4'>
              <Col className='order-xl-1' xl='12'>
                <Card className='bg-secondary shadow'>
                  <CardHeader className='bg-white border-0'>
                    <Row className='align-items-center'>
                      <Col xs='6'>
                        <h3 className='mb-0'>
                          Αίτηση myRAE (Α/Α: myRAE-{this.state.extra.serial})
                        </h3>
                      </Col>
                      <Col className='text-right' xs='6'>
                        {!this.state.isOpen ? (
                          <>
                            <Button
                              className='btn btn-link' // You can style this to your liking
                              onClick={this.toggleCollapse}
                              size='sm'
                              color='info'
                            >
                              Προβολή στοιχείων
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className='btn btn-link' // You can style this to your liking
                              onClick={this.toggleCollapse}
                              size='sm'
                              color='info'
                            >
                              Απόκρυψη στοιχείων
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Collapse isOpen={this.state.isOpen}>
                        <Row>
                          <Col className='order-xl-1' xl='6'>
                            <Card className='bg-secondary shadow'>
                              <CardHeader className='bg-white border-0'>
                                <Row className='align-items-center'>
                                  <Col xs='8'>
                                    <h3 className='mb-0'>
                                      Βασικές Πληροφορίες
                                    </h3>
                                  </Col>
                                </Row>
                              </CardHeader>
                              <CardBody>
                                <div className='pl-lg-4'>
                                  <Row>
                                    <Col lg='6'>
                                      <FormGroup>
                                        <label
                                          className='form-control-label'
                                          htmlFor='input-email'
                                        >
                                          Όνομα Κατόχου
                                        </label>
                                        <Input
                                          className='form-control-alternative'
                                          id='input-email'
                                          placeholder='Όνομα Κατόχου'
                                          defaultValue={this.state.extra.name}
                                          type='text'
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                      <FormGroup>
                                        <label
                                          className='form-control-label'
                                          htmlFor='input-email'
                                        >
                                          Επίθετο Κατόχου
                                        </label>
                                        <Input
                                          className='form-control-alternative'
                                          id='input-email'
                                          placeholder='Επίθετο Κατόχου'
                                          defaultValue={this.state.extra.last}
                                          type='text'
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg='6'>
                                      <FormGroup>
                                        <label
                                          className='form-control-label'
                                          htmlFor='input-email'
                                        >
                                          ΑΦΜ Κατόχου
                                        </label>
                                        <Input
                                          className='form-control-alternative'
                                          id='input-email'
                                          placeholder='ΑΦΜ Κατόχου'
                                          defaultValue={this.state.extra.afm}
                                          type='text'
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                      <FormGroup>
                                        <label
                                          className='form-control-label'
                                          htmlFor='input-email'
                                        >
                                          Αριθμός Παροχής
                                        </label>
                                        <Input
                                          className='form-control-alternative'
                                          id='input-email'
                                          placeholder='Αριθμός Παροχής'
                                          defaultValue={this.state.extra.num}
                                          type='text'
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg='6'>
                                      <FormGroup>
                                        <label
                                          className='form-control-label'
                                          htmlFor='input-email'
                                        >
                                          Κατηγορία Αιτήματος
                                        </label>
                                        <Input
                                          className='form-control-alternative'
                                          id='input-email'
                                          defaultValue={
                                            this.state.extra.category
                                          }
                                          placeholder='Κατηγορία'
                                          type='text'
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                      <FormGroup>
                                        <label
                                          className='form-control-label'
                                          htmlFor='input-email'
                                        >
                                          Είδος Ενέργειας
                                        </label>
                                        <Input
                                          className='form-control-alternative'
                                          id='input-email'
                                          placeholder='Είδος Ενέργειας'
                                          defaultValue={this.state.extra.energy}
                                          type='text'
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg='6'>
                                      <FormGroup>
                                        <label
                                          className='form-control-label'
                                          htmlFor='input-email'
                                        >
                                          Είδος Παραλήπτη
                                        </label>
                                        <Input
                                          className='form-control-alternative'
                                          id='input-email'
                                          placeholder='Είδος Παραλήπτη'
                                          defaultValue={
                                            this.state.extra.typercvr
                                          }
                                          type='text'
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                      <FormGroup>
                                        <label
                                          className='form-control-label'
                                          htmlFor='input-email'
                                        >
                                          Παραλήπτης
                                        </label>
                                        <Input
                                          className='form-control-alternative'
                                          id='input-email'
                                          placeholder='Παραλήπτης'
                                          defaultValue={this.state.extra.rcvr}
                                          type='text'
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>

                                <hr className='my-4' />
                                <div className='pl-lg-4'>
                                  <Row>
                                    <Col md='12'>
                                      <h6 className='heading-small text-muted mb-4'>
                                        Σχολια/Παρατηρησεις Αιτηματος
                                      </h6>
                                      <Input
                                        className='form-control-alternative'
                                        id='input-email'
                                        defaultValue={this.state.extra.text}
                                        placeholder='Σχόλια/Παρατηρήσεις Αιτήματος'
                                        type='textarea'
                                        disabled={true}
                                      />
                                    </Col>
                                  </Row>
                                </div>

                                <hr className='my-4' />
                                <div className='pl-lg-4'>
                                  <Row>
                                    <Col md='6'>
                                      <h6 className='heading-small text-muted mb-4'>
                                        Εγγραφα απο Καταναλωτη
                                      </h6>
                                    </Col>
                                    {this.state.consumerFiles.length > 0 && (
                                      <>
                                        <Col md='6'>
                                          <Button
                                            color='success'
                                            size='sm'
                                            onClick={() => {
                                              this.downloadAllFiles(
                                                this.state.consumerFiles
                                              )
                                            }}
                                          >
                                            Λήψη όλων
                                          </Button>
                                        </Col>
                                      </>
                                    )}

                                    <Table
                                      className='align-items-center table-flush'
                                      responsive
                                    >
                                      <thead className='thead-light'>
                                        <tr>
                                          <th scope='col'>Ονομα αρχειου</th>
                                          <th scope='col'>
                                            Ημερομηνια Αναρτησης
                                          </th>
                                          <th scope='col'>Ενεργειες</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.consumerFiles.map(
                                          (dynamicFiles) => (
                                            <tr>
                                              <td>{dynamicFiles.FileName}</td>
                                              <td>
                                                {Moment(dynamicFiles.UploadDate)
                                                  .locale("el")
                                                  .format("dddd, DD MMM YYYY")}
                                              </td>
                                              <td>
                                                <Button
                                                  color='primary'
                                                  href={dynamicFiles.Link}
                                                  target='_blank'
                                                  title='Προβολή'
                                                  className='btn-sm btn-primary'
                                                >
                                                  <i className='fas fa-eye' />
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    this.handleDownload(
                                                      dynamicFiles.Link,
                                                      dynamicFiles.Type,
                                                      dynamicFiles.FileName
                                                    )
                                                  }}
                                                  color='success'
                                                  size='sm'
                                                  title='Λήψη'
                                                >
                                                  <i className='fas fa-download' />
                                                </Button>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </Row>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col className='order-xl-1' xl='6'>
                            <Card className='bg-secondary shadow'>
                              <CardHeader className='bg-white border-0'>
                                <Row className='align-items-center'>
                                  <Col xs='8'>
                                    <h3 className='mb-0'>Έκβαση αιτήματος</h3>
                                  </Col>
                                </Row>
                              </CardHeader>
                              <CardBody>
                                <Col md='12'>
                                  <h6 className='heading-small text-muted mb-2'>
                                    Απαντηση Προμηθευτη / Διαχειριστη (
                                    {this.state.extra.date})
                                  </h6>
                                  <Input
                                    className='form-control-alternative'
                                    id='input-email'
                                    defaultValue={this.state.extra.reply}
                                    placeholder='Απάντηση Παραλήπτη'
                                    type='textarea'
                                    disabled={true}
                                  />
                                </Col>

                                <hr />

                                {this.state.hasReplies && (
                                  <Card className='bg-secondary shadow'>
                                    <CardHeader className='bg-white border-0'>
                                      <Row className='align-items-center'>
                                        <Col xs='8'>
                                          <h4 className='mb-0'>
                                            Ανταλλαγή Μηνυμάτων
                                          </h4>
                                        </Col>
                                        <Col
                                          className='text-right'
                                          xs='4'
                                        ></Col>
                                      </Row>
                                    </CardHeader>
                                    <CardBody>
                                      <Col
                                        md='12'
                                        style={{
                                          height: "38vh",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        {this.state.replies.map(
                                          (dynamicReplies) => (
                                            <div
                                              class='container'
                                              style={{
                                                textAlign:
                                                  dynamicReplies.UserType == 1
                                                    ? "right"
                                                    : "left",
                                                background:
                                                  dynamicReplies.UserType == 1
                                                    ? "powderblue"
                                                    : "lightgray  ",
                                                color: "black",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <strong
                                                style={{ margin: "0px" }}
                                                class={
                                                  dynamicReplies.UserType == 1
                                                    ? "time-right"
                                                    : "time-left"
                                                }
                                              >
                                                {dynamicReplies.user}
                                              </strong>
                                              <p
                                                style={{ margin: "0px" }}
                                                class={
                                                  dynamicReplies.UserType == 1
                                                    ? "time-right"
                                                    : "time-left"
                                                }
                                              >
                                                {Moment(
                                                  dynamicReplies.DateCreated
                                                )
                                                  .locale("el")
                                                  .format(
                                                    "DD MMM YYYY HH:mm:ss"
                                                  )}
                                              </p>
                                              <h4>
                                                <i>{dynamicReplies.Message}</i>
                                              </h4>
                                            </div>
                                          )
                                        )}
                                      </Col>
                                    </CardBody>
                                  </Card>
                                )}
                                <hr />
                                <div
                                  className='pl-lg-4'
                                  style={{ width: "100%" }}
                                >
                                  <Row>
                                    <Col md='6'>
                                      <h6 className='heading-small text-muted mb-4'>
                                        Εγγραφα Παραληπτη
                                      </h6>
                                    </Col>
                                    {this.state.receiverFiles.length > 1 && (
                                      <>
                                        <Col md='6'>
                                          <Button
                                            color='success'
                                            size='sm'
                                            onClick={() => {
                                              this.downloadAllFiles(
                                                this.state.receiverFiles
                                              )
                                            }}
                                          >
                                            Λήψη όλων
                                          </Button>
                                        </Col>
                                      </>
                                    )}

                                    <Table
                                      className='align-items-center table-flush'
                                      responsive
                                    >
                                      <thead className='thead-light'>
                                        <tr>
                                          <th scope='col'>Ονομα αρχειου</th>
                                          <th scope='col'>
                                            Ημερομηνια Αναρτησης
                                          </th>
                                          <th scope='col'>Ενεργειες</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.receiverFiles.map(
                                          (dynamicFiles) => (
                                            <tr>
                                              <td>{dynamicFiles.FileName}</td>
                                              <td>
                                                {Moment(dynamicFiles.UploadDate)
                                                  .locale("el")
                                                  .format("dddd, DD MMM YYYY")}
                                              </td>
                                              <td>
                                                <Button
                                                  color='primary'
                                                  href={dynamicFiles.Link}
                                                  target='_blank'
                                                  title='Προβολή'
                                                  className='btn-sm btn-primary'
                                                >
                                                  <i className='fas fa-eye' />
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    this.handleDownload(
                                                      dynamicFiles.Link,
                                                      dynamicFiles.Type,
                                                      dynamicFiles.FileName
                                                    )
                                                  }}
                                                  color='success'
                                                  size='sm'
                                                  title='Λήψη'
                                                >
                                                  <i className='fas fa-download' />
                                                </Button>
                                                {this.state.user.PersonRole ==
                                                "Admin"? (
                                                  <>
                                                    <Button
                                                      color='danger'
                                                      onClick={() => {
                                                        this.deleteFile(
                                                          dynamicFiles.Id
                                                        )
                                                      }}
                                                      size='sm'
                                                      title='Διαγραφή'
                                                    >
                                                      <i className='fas fa-trash' />
                                                    </Button>
                                                  </>
                                                ) : (
                                                  <> </>
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </Row>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Collapse>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col className='order-xl-1' xl='12'>
                <Card className='bg-secondary shadow'>
                  <CardHeader className='bg-white border-0'>
                    <Row className='align-items-center'>
                      <Col xs='6'>
                        <h3 className='mb-0'>
                          Αίτηση προς Ενεργειακό Διαμεσολαβητή (Α/Α:{" "}
                          {this.state.instance.SerialNumber})
                        </h3>
                      </Col>
                      <Col className='text-right' xs='6'>
                        {!this.state.isSecondOpen ? (
                          <>
                            <Button
                              className='btn btn-link' // You can style this to your liking
                              onClick={this.toggleSecondCollapse}
                              size='sm'
                              color='info'
                            >
                              Προβολή στοιχείων
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className='btn btn-link' // You can style this to your liking
                              onClick={this.toggleSecondCollapse}
                              size='sm'
                              color='info'
                            >
                              Απόκρυψη στοιχείων
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Collapse isOpen={this.state.isSecondOpen}>
                        <div className='pl-lg-4'>
                          <Row>
                            <Col lg='6'>
                              <FormGroup>
                                <label
                                  className='form-control-label'
                                  htmlFor='input-email'
                                >
                                  Πατρώνυμο
                                </label>
                                <Input
                                  className='form-control-alternative'
                                  id='input-email'
                                  placeholder='Πατρώνυμο'
                                  defaultValue={
                                    this.state.enomosExtra.fathername
                                  }
                                  type='text'
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg='6'>
                              <FormGroup>
                                <label
                                  className='form-control-label'
                                  htmlFor='input-email'
                                >
                                  Αριθμός Ταυτότητας
                                </label>
                                <Input
                                  className='form-control-alternative'
                                  id='input-email'
                                  placeholder='Αριθμός Ταυτότητας'
                                  defaultValue={this.state.enomosExtra.id}
                                  type='text'
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg='6'>
                              <FormGroup>
                                <label
                                  className='form-control-label'
                                  htmlFor='input-email'
                                >
                                  Ταχυδρομική Διεύθυνση
                                </label>
                                <Input
                                  className='form-control-alternative'
                                  id='input-email'
                                  placeholder='Ταχυδρομική Διεύθυνση'
                                  defaultValue={
                                    this.state.enomosExtra.postalcode
                                  }
                                  type='text'
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg='6'>
                              <FormGroup>
                                <label
                                  className='form-control-label'
                                  htmlFor='input-email'
                                >
                                  Email
                                </label>
                                <Input
                                  className='form-control-alternative'
                                  id='input-email'
                                  placeholder='Email'
                                  defaultValue={
                                    this.state.enomosExtra.email
                                  }
                                  type='text'
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg='6'>
                              <FormGroup>
                                <label
                                  className='form-control-label'
                                  htmlFor='input-email'
                                >
                                  Τηλέφωνο Επικοινωνίας
                                </label>
                                <Input
                                  className='form-control-alternative'
                                  id='input-email'
                                  placeholder='Τηλέφωνο Επικοινωνίας'
                                  defaultValue={
                                    this.state.enomosExtra.phone
                                  }
                                  type='text'
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className='mt-4'></Row>

                          <Row>
                            <Col lg='6'>
                              <FormGroup>
                                <label
                                  className='form-control-label'
                                  htmlFor='input-email'
                                >
                                  Το αναφερόμενο θέμα αποτελεί αντικείμενο
                                  δικαστικών διαδικασιών;*
                                </label>
                                <Input
                                  className='form-control-alternative'
                                  id='input-email'
                                  defaultValue={
                                    this.state.enomosExtra.courtproceedings
                                  }
                                  placeholder='Το αναφερόμενο θέμα αποτελεί αντικείμενο δικαστικών διαδικασιών;'
                                  type='text'
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg='6'>
                              <FormGroup>
                                <label
                                  className='form-control-label'
                                  htmlFor='input-email'
                                >
                                  Έχετε απευθυνθεί σε άλλον φορέα εναλλακτικής
                                  επίλυσης διαφορών;
                                </label>
                                <Input
                                  className='form-control-alternative'
                                  id='input-email'
                                  placeholder='Έχετε απευθυνθεί σε άλλον φορέα εναλλακτικής επίλυσης διαφορών;'
                                  defaultValue={this.state.enomosExtra.advocate}
                                  type='text'
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg='6'>
                              <FormGroup>
                                <label
                                  className='form-control-label'
                                  htmlFor='input-email'
                                >
                                  Παρακαλούμε περιγράψτε το παράπονο σας και
                                  τους λόγους που μετά τις απαντήσεις που λάβατε
                                  μέσω του myRAE επιθυμείτε να προχωρήσετε στις
                                  υπηρεσίες του ENOMOS
                                </label>
                                <Input
                                  className='form-control-alternative'
                                  id='input-email'
                                  placeholder='Παρακαλούμε περιγράψτε το παράπονο σας και τους λόγους που μετά τις απαντήσεις που λάβατε μέσω του myRAE επιθυμείτε να προχωρήσετε στις υπηρεσίες του ENOMOS'
                                  defaultValue={
                                    this.state.enomosExtra.reasoning
                                  }
                                  type='textarea'
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg='6'>
                              <FormGroup>
                                <label
                                  className='form-control-label'
                                  htmlFor='input-email'
                                >
                                  Παρακαλούμε περιγράψτε τι είναι αυτό που
                                  ζητάτε από τον Ενεργειακό Μεσολαβητή. Ποια
                                  είναι η πρόταση/απαίτηση σας για την επίλυση
                                  του θέματος σας.
                                </label>
                                <Input
                                  className='form-control-alternative'
                                  id='input-email'
                                  placeholder='Παρακαλούμε περιγράψτε τι είναι αυτό που ζητάτε από τον Ενεργειακό Μεσολαβητή. Ποια είναι η πρόταση/απαίτηση σας για την επίλυση του θέματος σας.'
                                  defaultValue={
                                    this.state.enomosExtra.suggestions
                                  }
                                  type='textarea'
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className='order-xl-1' xl='12'>
                              <Card className='bg-secondary shadow'>
                                <CardHeader className='bg-white border-0'>
                                  <Row className='align-items-center'>
                                    <Col xs='6'>
                                      <h3 className='mb-0'>
                                        Στοιχεία Εξουσιοδοτημένου Προσώπου
                                      </h3>
                                    </Col>
                                    <Col className='text-right' xs='6'>
                                      {!this.state.isThirdOpen ? (
                                        <>
                                          <Button
                                            className='btn btn-link' // You can style this to your liking
                                            onClick={this.toggleThirdCollapse}
                                            size='sm'
                                            color='info'
                                          >
                                            Προβολή στοιχείων
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            className='btn btn-link' // You can style this to your liking
                                            onClick={this.toggleThirdCollapse}
                                            size='sm'
                                            color='info'
                                          >
                                            Απόκρυψη στοιχείων
                                          </Button>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                </CardHeader>
                                <CardBody>
                                  <Form>
                                    <Collapse isOpen={this.state.isThirdOpen}>
                                      <Row>
                                        <Col lg='6'>
                                          <FormGroup>
                                            <label
                                              className='form-control-label'
                                              htmlFor='input-email'
                                            >
                                              Όνομα
                                            </label>
                                            <Input
                                              className='form-control-alternative'
                                              id='input-email'
                                              placeholder='Όνομα'
                                              defaultValue={
                                                this.state.enomosExtra
                                                  .authorizedname
                                              }
                                              type='text'
                                              disabled={true}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                          <FormGroup>
                                            <label
                                              className='form-control-label'
                                              htmlFor='input-email'
                                            >
                                              Επώνυμο
                                            </label>
                                            <Input
                                              className='form-control-alternative'
                                              id='input-email'
                                              placeholder='Επώνυμο'
                                              defaultValue={
                                                this.state.enomosExtra
                                                  .authorizedsurname
                                              }
                                              type='text'
                                              disabled={true}
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg='6'>
                                          <FormGroup>
                                            <label
                                              className='form-control-label'
                                              htmlFor='input-email'
                                            >
                                              Πατρώνυμο
                                            </label>
                                            <Input
                                              className='form-control-alternative'
                                              id='input-email'
                                              placeholder='Πατρώνυμο'
                                              defaultValue={
                                                this.state.enomosExtra
                                                  .authorizedfathername
                                              }
                                              type='text'
                                              disabled={true}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                          <FormGroup>
                                            <label
                                              className='form-control-label'
                                              htmlFor='input-email'
                                            >
                                              Αριθμός Ταυτότητας
                                            </label>
                                            <Input
                                              className='form-control-alternative'
                                              id='input-email'
                                              placeholder='Αριθμός Ταυτότητας'
                                              defaultValue={
                                                this.state.enomosExtra
                                                  .authorizedid
                                              }
                                              type='text'
                                              disabled={true}
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg='6'>
                                          <FormGroup>
                                            <label
                                              className='form-control-label'
                                              htmlFor='input-email'
                                            >
                                              ΑΦΜ
                                            </label>
                                            <Input
                                              className='form-control-alternative'
                                              id='input-email'
                                              placeholder='ΑΦΜ'
                                              defaultValue={
                                                this.state.enomosExtra
                                                  .authorizedafm
                                              }
                                              type='text'
                                              disabled={true}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                          <FormGroup>
                                            <label
                                              className='form-control-label'
                                              htmlFor='input-email'
                                            >
                                              Ταχυδρομική Διεύθυνση
                                            </label>
                                            <Input
                                              className='form-control-alternative'
                                              id='input-email'
                                              placeholder='Ταχυδρομική Διεύθυνση'
                                              defaultValue={
                                                this.state.enomosExtra
                                                  .authorizedpostalcode
                                              }
                                              type='text'
                                              disabled={true}
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    </Collapse>
                                  </Form>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </div>
                        <hr className='my-4' />
                        <div className='pl-lg-4'>
                          <Row>
                            <Col md='6'>
                              <h6 className='heading-small text-muted mb-4'>
                                Εγγραφα απο Καταναλωτη
                              </h6>
                            </Col>
                            {this.state.consumerEnomosFiles.length > 0 && (
                              <>
                                <Col md='6'>
                                  <Button
                                    color='success'
                                    size='sm'
                                    onClick={() => {
                                      this.downloadAllFiles(
                                        this.state.consumerEnomosFiles
                                      )
                                    }}
                                  >
                                    Λήψη όλων
                                  </Button>
                                </Col>
                              </>
                            )}

                            <Table
                              className='align-items-center table-flush'
                              responsive
                            >
                              <thead className='thead-light'>
                                <tr>
                                  <th scope='col'>Ονομα αρχειου</th>
                                  <th scope='col'>Ημερομηνια Αναρτησης</th>
                                  <th scope='col'>Ενεργειες</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.consumerEnomosFiles.map(
                                  (dynamicFiles) => (
                                    <tr>
                                      <td>{dynamicFiles.FileName}</td>
                                      <td>
                                        {Moment(dynamicFiles.UploadDate)
                                          .locale("el")
                                          .format("dddd, DD MMM YYYY")}
                                      </td>
                                      <td>
                                        <Button
                                          color='primary'
                                          href={dynamicFiles.Link}
                                          target='_blank'
                                          title='Προβολή'
                                          className='btn-sm btn-primary'
                                        >
                                          <i className='fas fa-eye' />
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            this.handleDownload(
                                              dynamicFiles.Link,
                                              dynamicFiles.Type,
                                              dynamicFiles.FileName
                                            )
                                          }}
                                          color='success'
                                          size='sm'
                                          title='Λήψη'
                                        >
                                          <i className='fas fa-download' />
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Row>
                        </div>
                      </Collapse>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col className='order-xl-1' xl='12'>
                <Card className='bg-secondary shadow'>
                  <CardHeader className='bg-white border-0'>
                    <Row className='align-items-center'>
                      <Col xs='6'>
                        <h3 className='mb-0'>
                          Διάλογος με Εταιρεία και Καταναλωτή
                        </h3>
                      </Col>
                      <Col className='text-right' xs='6'>
                        {!this.state.isFourthOpen ? (
                          <>
                            <Button
                              className='btn btn-link' // You can style this to your liking
                              onClick={this.toggleFourthCollapse}
                              size='sm'
                              color='info'
                            >
                              Προβολή στοιχείων
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className='btn btn-link' // You can style this to your liking
                              onClick={this.toggleFourthCollapse}
                              size='sm'
                              color='info'
                            >
                              Απόκρυψη στοιχείων
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Collapse isOpen={this.state.isFourthOpen}>
                        <Row>
                          <Col xs={6}>
                            <Card className='bg-secondary shadow'>
                              <CardHeader className='bg-white border-0'>
                                <Row className='align-items-center'>
                                  <Col xs='12'>
                                    <h4 className='mb-0'>
                                      Ανταλλαγή Μηνυμάτων με τον Καταναλωτή
                                    </h4>
                                  </Col>
                                </Row>
                              </CardHeader>
                              <CardBody>
                                {this.state.hasRepliesEnomos && (
                                  <Col
                                    md='11'
                                    style={{
                                      height: "38vh",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {this.state.repliesEnomos
                                      .filter(
                                        (dynamicReplies) =>
                                          (dynamicReplies.UserType === 0 ||
                                            dynamicReplies.UserType === 1) &&
                                          (dynamicReplies.ReceiverType === 0 ||
                                            dynamicReplies.ReceiverType === 1)
                                      )
                                      .map((dynamicReplies) => (
                                        <div
                                          className='container'
                                          style={{
                                            textAlign:
                                              dynamicReplies.UserType === 1
                                                ? "right"
                                                : "left",
                                            background:
                                              dynamicReplies.UserType === 1
                                                ? "powderblue"
                                                : "lightgray",
                                            color: "black",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          <strong
                                            style={{ margin: "0px" }}
                                            className={
                                              dynamicReplies.UserType === 1
                                                ? "time-right"
                                                : "time-left"
                                            }
                                          >
                                            {dynamicReplies.user}
                                          </strong>
                                          <p
                                            style={{ margin: "0px" }}
                                            className={
                                              dynamicReplies.UserType === 1
                                                ? "time-right"
                                                : "time-left"
                                            }
                                          >
                                            {Moment(dynamicReplies.DateCreated)
                                              .locale("el")
                                              .format("DD MMM YYYY HH:mm:ss")}
                                          </p>
                                          <h4>
                                            <i>{dynamicReplies.Message}</i>
                                          </h4>
                                        </div>
                                      ))}
                                  </Col>
                                )}
                              </CardBody>
                              {this.state.instance.LawyerAssignedId ==
                              this.state.user.Id ? (
                                <>
                                  <hr />
                                  <Row className='align-items-center'>
                                    <Col xs='11'>
                                      <div
                                        className='pl-lg-4'
                                        style={{ width: "100%" }}
                                      >
                                        <h6 className='heading-small text-muted mb-4'>
                                          Νεο Σχολιο
                                        </h6>
                                        <Input
                                          className='form-control-alternative'
                                          id='input-email'
                                          placeholder='Σχόλιο'
                                          type='textarea'
                                          disabled={
                                            this.state.instance.Status != 1
                                          }
                                          onChange={(evt) =>
                                            this.updateAnswerToConsumer(evt)
                                          }
                                        />
                                        <br />
                                        <Col lg='10'>
                                          <label
                                            className='btn-sm btn-primary'
                                            htmlFor='file-answer'
                                          >
                                            Επιλογή αρχείου
                                          </label>
                                          <i
                                            class='fa fa-info-circle'
                                            data-tip
                                            data-for={
                                              "info-form-control-alternative"
                                            }
                                            aria-hidden='true'
                                            style={{
                                              marginLeft: "5px",
                                              marginTop: "3px",
                                            }}
                                          ></i>
                                          <Input
                                            className='form-control-alternative'
                                            id='file-answer'
                                            style={{ visibility: "hidden" }}
                                            type='file'
                                            onChange={(evt) =>
                                              this.uploadFile(evt, 1, 0)
                                            }
                                          />

                                          <ReactTooltip
                                            id={"info-form-control-alternative"}
                                            type='info'
                                          >
                                            <span>
                                              Μέγιστο μέγεθος αρχείο 5ΜΒ.
                                            </span>
                                            <div id='imageattooltip'></div>
                                          </ReactTooltip>
                                          {/* <Button
                                      color="primary"
                                      onClick={evt => this.uploadFile()}
                                      size="sm"
                                    >
                                      Επισύναψη
                                    </Button> */}
                                        </Col>
                                        <br />
                                        <Button
                                          color='success'
                                          onClick={(evt) =>
                                            this.sendAnswer(
                                              0,
                                              this.state.answerToConsumer
                                            )
                                          }
                                          size='sm'
                                          style={{ width: "100%" }}
                                          disabled={
                                            this.state.commentToConsumerDisabled
                                          }
                                        >
                                          Αποστολή προς Καταναλωτή
                                        </Button>
                                      </div>
                                      <br />
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <></>
                              )}
                            </Card>
                          </Col>
                          {this.state.instance.CompanyAssigned != null && (
                            <Col xs='6'>
                              <Card className='bg-secondary shadow'>
                                <CardHeader className='bg-white border-0'>
                                  <Row className='align-items-center'>
                                    <Col xs='12'>
                                      <h4 className='mb-0'>
                                        Ανταλλαγή Μηνυμάτων με την Εταιρεία
                                      </h4>
                                    </Col>
                                  </Row>
                                </CardHeader>
                                <CardBody>
                                  {this.state.hasRepliesEnomos && (
                                    <Col
                                      md='11'
                                      style={{
                                        height: "38vh",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {this.state.repliesEnomos
                                        .filter(
                                          (dynamicReplies) =>
                                            (dynamicReplies.UserType === 1 ||
                                              dynamicReplies.UserType === 2) &&
                                            (dynamicReplies.ReceiverType ===
                                              1 ||
                                              dynamicReplies.ReceiverType === 2)
                                        )
                                        .map((dynamicReplies) => (
                                          <div
                                            className='container'
                                            style={{
                                              textAlign:
                                                dynamicReplies.UserType === 1
                                                  ? "right"
                                                  : "left",
                                              background:
                                                dynamicReplies.UserType === 1
                                                  ? "powderblue"
                                                  : "lightgray",
                                              color: "black",
                                              borderRadius: "8px",
                                            }}
                                          >
                                            <strong
                                              style={{ margin: "0px" }}
                                              className={
                                                dynamicReplies.UserType === 1
                                                  ? "time-right"
                                                  : "time-left"
                                              }
                                            >
                                              {dynamicReplies.user}
                                            </strong>
                                            <p
                                              style={{ margin: "0px" }}
                                              className={
                                                dynamicReplies.UserType === 1
                                                  ? "time-right"
                                                  : "time-left"
                                              }
                                            >
                                              {Moment(
                                                dynamicReplies.DateCreated
                                              )
                                                .locale("el")
                                                .format("DD MMM YYYY HH:mm:ss")}
                                            </p>
                                            <h4>
                                              <i>{dynamicReplies.Message}</i>
                                            </h4>
                                          </div>
                                        ))}
                                    </Col>
                                  )}
                                </CardBody>
                                {this.state.instance.LawyerAssignedId ==
                                this.state.user.Id ? (
                                  <>
                                    <hr />
                                    <Row className='align-items-center'>
                                      <Col xs='11'>
                                        <div
                                          className='pl-lg-4'
                                          style={{ width: "100%" }}
                                        >
                                          <h6 className='heading-small text-muted mb-4'>
                                            Νεο Σχολιο
                                          </h6>
                                          <Input
                                            className='form-control-alternative'
                                            id='input-email'
                                            placeholder='Σχόλιο'
                                            type='textarea'
                                            disabled={
                                              this.state.instance.Status != 1
                                            }
                                            onChange={(evt) =>
                                              this.updateAnswerToCompany(evt)
                                            }
                                          />
                                          <br />
                                          <Col lg='10'>
                                            <label
                                              className='btn-sm btn-primary'
                                              htmlFor='file-answer-tocompany'
                                            >
                                              Επιλογή αρχείου
                                            </label>
                                            <i
                                              class='fa fa-info-circle'
                                              data-tip
                                              data-for={
                                                "info-form-control-alternative"
                                              }
                                              aria-hidden='true'
                                              style={{
                                                marginLeft: "5px",
                                                marginTop: "3px",
                                              }}
                                            ></i>
                                            <Input
                                              className='form-control-alternative'
                                              id='file-answer-tocompany'
                                              style={{ visibility: "hidden" }}
                                              type='file'
                                              onChange={(evt) =>
                                                this.uploadFile(evt, 1, 2)
                                              }
                                            />

                                            <ReactTooltip
                                              id={
                                                "info-form-control-alternative"
                                              }
                                              type='info'
                                            >
                                              <span>
                                                Μέγιστο μέγεθος αρχείο 5ΜΒ.
                                              </span>
                                              <div id='imageattooltip'></div>
                                            </ReactTooltip>
                                            {/* <Button
                                      color="primary"
                                      onClick={evt => this.uploadFile()}
                                      size="sm"
                                    >
                                      Επισύναψη
                                    </Button> */}
                                          </Col>
                                          <br />
                                          <Button
                                            color='success'
                                            onClick={(evt) =>
                                              this.sendAnswer(
                                                2,
                                                this.state.answerToCompany
                                              )
                                            }
                                            size='sm'
                                            style={{ width: "100%" }}
                                            disabled={
                                              this.state
                                                .commentToCompanyDisabled
                                            }
                                          >
                                            Αποστολή προς Εταιρεία
                                          </Button>
                                        </div>
                                        <br />
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Card>
                            </Col>
                          )}
                          <br />
                          <Col xs='12'>
                            <Card className='bg-secondary shadow'>
                              <CardHeader className='bg-white border-0'>
                                <Row className='align-items-center'>
                                  <Col xs='12'>
                                    <h4 className='mb-0'>Έγγραφα Αιτήματος</h4>
                                  </Col>
                                </Row>
                              </CardHeader>
                              <CardBody>
                                <Form>
                                  <Row>
                                    <div className='col'>
                                      <Row>
                                        <Col md='6'>
                                          <h6 className='heading-small text-muted mb-4'>
                                            Εγγραφα απο Καταναλωτη
                                          </h6>
                                        </Col>
                                        {this.state.consumerEnomosFiles.length >
                                          0 && (
                                          <>
                                            <Col md='6'>
                                              <Button
                                                color='success'
                                                size='sm'
                                                onClick={() => {
                                                  this.downloadAllFiles(
                                                    this.state
                                                      .consumerEnomosFiles
                                                  )
                                                }}
                                              >
                                                Λήψη όλων
                                              </Button>
                                            </Col>
                                          </>
                                        )}

                                        <Table
                                          className='align-items-center table-flush'
                                          responsive
                                        >
                                          <thead className='thead-light'>
                                            <tr>
                                              <th scope='col'>Ονομα αρχειου</th>
                                              <th scope='col'>
                                                Ημερομηνια Αναρτησης
                                              </th>
                                              <th scope='col'>Ενεργειες</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.consumerEnomosFiles.map(
                                              (dynamicFiles) => (
                                                <tr>
                                                  <td>
                                                    {dynamicFiles.FileName}
                                                  </td>
                                                  <td>
                                                    {Moment(
                                                      dynamicFiles.UploadDate
                                                    )
                                                      .locale("el")
                                                      .format(
                                                        "dddd, DD MMM YYYY"
                                                      )}
                                                  </td>
                                                  <td>
                                                    <Button
                                                      color='primary'
                                                      href={dynamicFiles.Link}
                                                      target='_blank'
                                                      title='Προβολή'
                                                      className='btn-sm btn-primary'
                                                    >
                                                      <i className='fas fa-eye' />
                                                    </Button>
                                                    <Button
                                                      onClick={() => {
                                                        this.handleDownload(
                                                          dynamicFiles.Link,
                                                          dynamicFiles.Type,
                                                          dynamicFiles.FileName
                                                        )
                                                      }}
                                                      color='success'
                                                      size='sm'
                                                      title='Λήψη'
                                                    >
                                                      <i className='fas fa-download' />
                                                    </Button>
                                                    {this.state.user
                                                      .PersonRole == "Admin"? (
                                                      <>
                                                        <Button
                                                          color='danger'
                                                          onClick={() => {
                                                            this.deleteFile(
                                                              dynamicFiles.Id
                                                            )
                                                          }}
                                                          size='sm'
                                                          title='Διαγραφή'
                                                        >
                                                          <i className='fas fa-trash' />
                                                        </Button>
                                                      </>
                                                    ) : (
                                                      <> </>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </Table>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col md='6'>
                                          <h6 className='heading-small text-muted mb-4'>
                                            Εγγραφα προς Καταναλωτη
                                          </h6>
                                        </Col>
                                        {this.state.lawyerToConsumerEnomosFiles
                                          .length > 1 && (
                                          <>
                                            <Col md='6'>
                                              <Button
                                                color='success'
                                                size='sm'
                                                onClick={() => {
                                                  this.downloadAllFiles(
                                                    this.state
                                                      .lawyerToConsumerEnomosFiles
                                                  )
                                                }}
                                              >
                                                Λήψη όλων
                                              </Button>
                                            </Col>
                                          </>
                                        )}

                                        <Table
                                          className='align-items-center table-flush'
                                          responsive
                                        >
                                          <thead className='thead-light'>
                                            <tr>
                                              <th scope='col'>Ονομα αρχειου</th>
                                              <th scope='col'>
                                                Ημερομηνια Αναρτησης
                                              </th>
                                              <th scope='col'>Ενεργειες</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.lawyerToConsumerEnomosFiles.map(
                                              (dynamicFiles) => (
                                                <tr>
                                                  <td>
                                                    {dynamicFiles.FileName}
                                                  </td>
                                                  <td>
                                                    {Moment(
                                                      dynamicFiles.UploadDate
                                                    )
                                                      .locale("el")
                                                      .format(
                                                        "dddd, DD MMM YYYY"
                                                      )}
                                                  </td>
                                                  <td>
                                                    <Button
                                                      color='primary'
                                                      href={dynamicFiles.Link}
                                                      target='_blank'
                                                      title='Προβολή'
                                                      className='btn-sm btn-primary'
                                                    >
                                                      <i className='fas fa-eye' />
                                                    </Button>
                                                    <Button
                                                      onClick={() => {
                                                        this.handleDownload(
                                                          dynamicFiles.Link,
                                                          dynamicFiles.Type,
                                                          dynamicFiles.FileName
                                                        )
                                                      }}
                                                      color='success'
                                                      size='sm'
                                                      title='Λήψη'
                                                    >
                                                      <i className='fas fa-download' />
                                                    </Button>
                                                    {this.state.user
                                                      .PersonRole == "Admin" || this.state.user.Id == this.state.instance.LawyerAssignedId ? (
                                                      <>
                                                        <Button
                                                          color='danger'
                                                          onClick={() => {
                                                            this.deleteFile(
                                                              dynamicFiles.Id
                                                            )
                                                          }}
                                                          size='sm'
                                                          title='Διαγραφή'
                                                        >
                                                          <i className='fas fa-trash' />
                                                        </Button>
                                                      </>
                                                    ) : (
                                                      <> </>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </Table>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col md='6'>
                                          <h6 className='heading-small text-muted mb-4'>
                                            Εγγραφα προς Εταιρεια
                                          </h6>
                                        </Col>
                                        {this.state.lawyerToReceiverEnomosFiles
                                          .length > 1 && (
                                          <>
                                            <Col md='6'>
                                              <Button
                                                color='success'
                                                size='sm'
                                                onClick={() => {
                                                  this.downloadAllFiles(
                                                    this.state
                                                      .lawyerToReceiverEnomosFiles
                                                  )
                                                }}
                                              >
                                                Λήψη όλων
                                              </Button>
                                            </Col>
                                          </>
                                        )}

                                        <Table
                                          className='align-items-center table-flush'
                                          responsive
                                        >
                                          <thead className='thead-light'>
                                            <tr>
                                              <th scope='col'>Ονομα αρχειου</th>
                                              <th scope='col'>
                                                Ημερομηνια Αναρτησης
                                              </th>
                                              <th scope='col'>Ενεργειες</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.lawyerToReceiverEnomosFiles.map(
                                              (dynamicFiles) => (
                                                <tr>
                                                  <td>
                                                    {dynamicFiles.FileName}
                                                  </td>
                                                  <td>
                                                    {Moment(
                                                      dynamicFiles.UploadDate
                                                    )
                                                      .locale("el")
                                                      .format(
                                                        "dddd, DD MMM YYYY"
                                                      )}
                                                  </td>
                                                  <td>
                                                    <Button
                                                      color='primary'
                                                      href={dynamicFiles.Link}
                                                      target='_blank'
                                                      title='Προβολή'
                                                      className='btn-sm btn-primary'
                                                    >
                                                      <i className='fas fa-eye' />
                                                    </Button>
                                                    <Button
                                                      onClick={() => {
                                                        this.handleDownload(
                                                          dynamicFiles.Link,
                                                          dynamicFiles.Type,
                                                          dynamicFiles.FileName
                                                        )
                                                      }}
                                                      color='success'
                                                      size='sm'
                                                      title='Λήψη'
                                                    >
                                                      <i className='fas fa-download' />
                                                    </Button>
                                                    {this.state.user
                                                      .PersonRole == "Admin" || this.state.user.Id == this.state.instance.LawyerAssignedId ? (
                                                      <>
                                                        <Button
                                                          color='danger'
                                                          onClick={() => {
                                                            this.deleteFile(
                                                              dynamicFiles.Id
                                                            )
                                                          }}
                                                          size='sm'
                                                          title='Διαγραφή'
                                                        >
                                                          <i className='fas fa-trash' />
                                                        </Button>
                                                      </>
                                                    ) : (
                                                      <> </>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </Table>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col md='6'>
                                          <h6 className='heading-small text-muted mb-4'>
                                            Εγγραφα απο Εταιρεια
                                          </h6>
                                        </Col>
                                        {this.state.receiverEnomosFiles.length >
                                          0 && (
                                          <>
                                            <Col md='6'>
                                              <Button
                                                color='success'
                                                size='sm'
                                                onClick={() => {
                                                  this.downloadAllFiles(
                                                    this.state
                                                      .receiverEnomosFiles
                                                  )
                                                }}
                                              >
                                                Λήψη όλων
                                              </Button>
                                            </Col>
                                          </>
                                        )}

                                        <Table
                                          className='align-items-center table-flush'
                                          responsive
                                        >
                                          <thead className='thead-light'>
                                            <tr>
                                              <th scope='col'>Ονομα αρχειου</th>
                                              <th scope='col'>
                                                Ημερομηνια Αναρτησης
                                              </th>
                                              <th scope='col'>Ενεργειες</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.receiverEnomosFiles.map(
                                              (dynamicFiles) => (
                                                <tr>
                                                  <td>
                                                    {dynamicFiles.FileName}
                                                  </td>
                                                  <td>
                                                    {Moment(
                                                      dynamicFiles.UploadDate
                                                    )
                                                      .locale("el")
                                                      .format(
                                                        "dddd, DD MMM YYYY"
                                                      )}
                                                  </td>
                                                  <td>
                                                    <Button
                                                      color='primary'
                                                      href={dynamicFiles.Link}
                                                      target='_blank'
                                                      title='Προβολή'
                                                      className='btn-sm btn-primary'
                                                    >
                                                      <i className='fas fa-eye' />
                                                    </Button>
                                                    <Button
                                                      onClick={() => {
                                                        this.handleDownload(
                                                          dynamicFiles.Link,
                                                          dynamicFiles.Type,
                                                          dynamicFiles.FileName
                                                        )
                                                      }}
                                                      color='success'
                                                      size='sm'
                                                      title='Λήψη'
                                                    >
                                                      <i className='fas fa-download' />
                                                    </Button>
                                                    {this.state.user
                                                      .PersonRole == "Admin" ? (
                                                      <>
                                                        <Button
                                                          color='danger'
                                                          onClick={() => {
                                                            this.deleteFile(
                                                              dynamicFiles.Id
                                                            )
                                                          }}
                                                          size='sm'
                                                          title='Διαγραφή'
                                                        >
                                                          <i className='fas fa-trash' />
                                                        </Button>
                                                      </>
                                                    ) : (
                                                      <> </>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </Table>
                                      </Row>
                                    </div>
                                  </Row>
                                </Form>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Collapse>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.state.instance.LawyerAssignedId == this.state.user.Id && (
              <Row className='mt-4'>
                <Col className='order-xl-1' xl='12'>
                  <Card className='bg-secondary shadow'>
                    <CardHeader className='bg-white border-0'>
                      <Row className='align-items-center'>
                        <Col xs='6'>
                          <h3 className='mb-0'>Σημειώσεις</h3>
                        </Col>
                        <Col className='text-right' xs='6'>
                          {!this.state.isFifthOpen ? (
                            <>
                              <Button
                                className='btn btn-link' // You can style this to your liking
                                onClick={this.toggleFifthCollapse}
                                size='sm'
                                color='info'
                              >
                                Προβολή στοιχείων
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                className='btn btn-link' // You can style this to your liking
                                onClick={this.toggleFifthCollapse}
                                size='sm'
                                color='info'
                              >
                                Απόκρυψη στοιχείων
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Collapse isOpen={this.state.isFifthOpen}>
                          {this.renderNotes()}
                          <hr />
                          <Row>
                            <Button
                              onClick={this.setShowThirdModal}
                              size='sm'
                              color='success'
                              style={{ marginLeft: "15px" }}
                            >
                              Προσθήκη σημείωσης
                            </Button>
                          </Row>
                        </Collapse>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </>
      )
    }
  }
}

export default InstanceDetails;
