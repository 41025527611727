export const ViewType = {
    rae: "rae",
    client: "client",
}

export const StateActionsLiterals = {
    GenerateOptions: (viewType, receiverKind, energyKind) => {
        let results = [];
        StateActionsLiterals.Literals.forEach(x => {

            let entryItem = StateActionsLiterals.GetLiteral(x.value, viewType);
            if (entryItem.default === undefined) {
                entryItem.default = x.value;
            }

            let children = [];
            //if (entryItem.children != null && entryItem.children.length > 0){
            children = [{ value: "ΟΛΑ", label: "ΟΛΑ" }];
            if (entryItem.children === undefined) { entryItem.children = []; }
            children = children.concat(entryItem.children);

            children.forEach(child => {
                if (child.label === undefined) {
                    child.label = child.value;
                }
            });
            //}

            //filter results based on receiver kinds
            // let isFilteredOutReceiverKinds = false;
            // if (x.isVisibleToReceiverKinds !== undefined && x.isVisibleToReceiverKinds.length > 0) {
            //     isFilteredOutReceiverKinds = true;
            //     if (x.isVisibleToReceiverKinds.includes(receiverKind ?? "") == true) {
            //         isFilteredOutReceiverKinds = false;
            //     }
            // }
            // if (isFilteredOutReceiverKinds == false) {
            //     results.push({
            //         value: x.value,
            //         label: entryItem.default,
            //         children: children
            //     })
            // }

            let isIncludedReceiverKinds = true;
            if (x.isVisibleToReceiverKinds !== undefined && x.isVisibleToReceiverKinds.length > 0) {
                isIncludedReceiverKinds = false;
                if (x.isVisibleToReceiverKinds.includes(receiverKind ?? "") == true) {
                    isIncludedReceiverKinds = true;
                }
            }

            if (x.isVisibleToMultipleCriteria) {
                isIncludedReceiverKinds = false;
                x.isVisibleToMultipleCriteria.forEach(x => {
                    if (isIncludedReceiverKinds == true) {
                        return;
                    }
                    if (x.receiverKind.includes(receiverKind ?? "") == true) {
                        if (x.energyKinds && x.energyKinds.length > 0) {
                            isIncludedReceiverKinds = x.energyKinds.includes(energyKind ?? "")
                        } else {
                            isIncludedReceiverKinds = true;
                        }
                    }
                });
            }


            if (isIncludedReceiverKinds == true) {
                results.push({
                    value: x.value,
                    label: entryItem.default,
                    children: children
                })
            }

        });
        return results;
    },
    GetLiteral: (valueKey, viewType) => {
        let literal = StateActionsLiterals.Literals.filter(x => x.value == valueKey)[0];
        switch (viewType) {
            case ViewType.rae:
                return literal.labels.rae;
                break;
            case ViewType.client:
            default:
                return literal.labels.client;
        }
    },
    Literals: [
        {
            value: "ΟΛΑ",
            labels: {
                client: {},
                rae: {}
            }
        },
        {
            value: "Την Σύμβαση με την εταιρία",
            isVisibleToReceiverKinds: ['ΟΛΑ', "Προμηθευτής Ενέργειας", "Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"],
            labels: {
                client: {
                    children: [
                        { value: "Την καθυστέρηση απάντησης σε αίτηση που έκανα για να λάβω Προσφορά" },
                        { value: "Καταχρηστικούς ή και παραπλανητικούς όρους στη Σύμβαση" },
                        { value: "Τις εμπορικές πρακτικές προώθησης από πλευράς της εταιρίας" },
                        { value: "Το προσωπικό πωλήσεων το οποίο επικοινώνησε μαζί μου" },
                        { value: "Την εγκυρότητα της υπογραφής στη Σύμβαση" },
                        { value: "Την ενημέρωση που έλαβα για τους όρους και τις υπηρεσίες που περιλαμβάνει η Σύμβαση" },
                        { value: "Την ενημέρωση που έλαβα για τις τιμές χρεώσεων" },
                        { value: "Την Ιστοσελίδα της εταιρίας" },
                        { value: "Την καταβολή εγγυήσης" },
                        { value: "ΔΕΝ ΕΧΕΙ ΕΠΙΛΕΧΘΕΙ ΥΠΟΚΑΤΗΓΟΡΙΑ" },
                    ]
                },
                rae: {
                    default: "ΣΥΜΒΑΣΗ",
                    children: [
                        { value: "Την καθυστέρηση απάντησης σε αίτηση που έκανα για να λάβω Προσφορά", label: "ΚΑΘΥΣΤΕΡΗΣΕΙΣ" },
                        { value: "Καταχρηστικούς ή και παραπλανητικούς όρους στη Σύμβαση", label: "ΚΑΤΑΧΡΗΣΤΙΚΟΙ ΟΡΟΙ" },
                        { value: "Τις εμπορικές πρακτικές προώθησης από πλευράς της εταιρίας", label: "ΕΜΠΟΡΙΚΕΣ ΠΡΑΚΤΙΚΕΣ" },
                        { value: "Το προσωπικό πωλήσεων το οποίο επικοινώνησε μαζί μου", label: "ΠΡΟΣΩΠΙΚΟ ΠΩΛΗΣΕΩΝ" },
                        { value: "Την εγκυρότητα της υπογραφής στη Σύμβαση", label: "ΕΓΚΥΡΟΤΗΤΑ" },
                        { value: "Την ενημέρωση που έλαβα για τους όρους και τις υπηρεσίες που περιλαμβάνει η Σύμβαση", label: "ΕΝΗΜΕΡΩΣΗ ΟΡΩΝ" },
                        { value: "Την ενημέρωση που έλαβα για τις τιμές χρεώσεων", label: "ΤΙΜΕΣ ΧΡΕΩΣΕΩΝ" },
                        { value: "Την Ιστοσελίδα της εταιρίας", label: "ΙΣΤΟΣΕΛΙΔΑ ΕΤΑΙΡΙΑΣ" },
                        { value: "Την καταβολή εγγυήσης", label: "ΕΓΓΥΗΣΗ" },
                        { value: "ΔΕΝ ΕΧΕΙ ΕΠΙΛΕΧΘΕΙ ΥΠΟΚΑΤΗΓΟΡΙΑ", label: "ΔΕΝ ΕΧΕΙ ΕΠΙΛΕΧΘΕΙ ΥΠΟΚΑΤΗΓΟΡΙΑ" }
                    ]
                }

            }
        },
        {
            value: "Την καταγγελία της Σύμβασης",
            isVisibleToReceiverKinds: ['ΟΛΑ', "Προμηθευτής Ενέργειας", "Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"],
            labels: {
                client: {
                    children: [
                        { value: "Την επιστροφή της εγγύησης" },
                        { value: "Το χρόνο αποστολής του εκκαθαριστικού" }
                    ]
                },
                rae: {
                    default: "ΚΑΤΑΓΓΕΛΙΑ ΣΥΜΒΑΣΗΣ",
                    children: [
                        { value: "Την επιστροφή της εγγύησης", label: "ΕΓΓΥΗΣΗ" },
                        { value: "Το χρόνο αποστολής του εκκαθαριστικού", label: "ΕΚΚΑΘΑΡΙΣΤΙΚΟ" }
                    ]
                }

            }
        },
        {
            value: "Τις πληρωμές των λογαριασμών",
            isVisibleToReceiverKinds: ['ΟΛΑ', "Προμηθευτής Ενέργειας", "Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"],
            labels: {
                client: {
                    children: [
                        { value: "Προβλήμα με τραπεζική συναλλαγή ή αυτόματη πληρωμή" },
                        { value: "Τις πιστώσεις στο λογαριασμό μου" },
                        { value: "Υπόλοιπο ή ανεξόφλητο ποσό" },
                        { value: "Τους τρόπους πληρωμής" },
                        { value: "Διακανονισμό οφειλών" }
                    ]
                },
                rae: {
                    default: "ΠΛΗΡΩΜΕΣ",
                    children: [
                        { value: "Προβλήμα με τραπεζική συναλλαγή ή αυτόματη πληρωμή", label: "ΤΡΑΠΕΖΙΚΕΣ ΣΥΝΑΛΛΑΓΕΣ" },
                        { value: "Τις πιστώσεις στο λογαριασμό μου", label: "ΠΙΣΤΩΣΕΙΣ" },
                        { value: "Υπόλοιπο ή ανεξόφλητο ποσό", label: "ΠΟΣΟ ΠΛΗΡΩΜΗΣ" },
                        { value: "Τους τρόπους πληρωμής", label: "ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ" },
                        { value: "Διακανονισμό οφειλών", label: "ΔΙΑΚΑΝΟΝΙΣΜΟΣ" },
                    ]
                }

            }
        },
        {
            value: "Τον λογαριασμό μου",
            isVisibleToReceiverKinds: ['ΟΛΑ', "Προμηθευτής Ενέργειας", "Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"],
            labels: {
                client: {
                    children: [
                        { value: "Τη σαφήνεια του λογαριασμού" },
                        { value: "Τις πιστώσεις στο λογαριασμό μου" },
                        { value: "Υπόλοιπο ή ανεξόφλητο ποσό" },
                        { value: "Τους τρόπους πληρωμής" },
                        { value: "Διακανονισμό οφειλών" }
                    ]
                },
                rae: {
                    default: "ΛΟΓΑΡΙΑΣΜΟΣ",
                    children: [
                        { value: "Τη σαφήνεια του λογαριασμού", label: "ΑΣΑΦΕΙΕΣ" },
                        { value: "Τη συχνότητα του λογαριασμού", label: "ΣΥΧΝΟΤΗΤΑ" },
                        { value: "Αμφισβήτηση κάποιων χρεώσεων", label: "ΑΜΦΙΣΒΗΤΗΣΗ ΧΡΕΩΣΕΩΝ" },
                        { value: "Τις Ρήτρες στον Λογαριασμό (ΟΤΣ, Αποχώρησης)", label: "ΡΗΤΡΕΣ" },
                        { value: "Την τροποποίηση Συμβατικών όρων & Χρεώσεων", label: "ΤΡΟΠΟΠΟΙΗΣΗ ΟΡΩΝ" },
                    ]
                }

            }
        },
        {
            value: "Την εξυπηρέτηση μου από την εταιρία",
            isVisibleToReceiverKinds: ['ΟΛΑ', "Προμηθευτής Ενέργειας", "Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"],
            labels: {
                client: {
                    children: [
                        { value: "Καθυστέρηση στην αλλαγή Προμηθευτή" },
                        { value: "Τον χρόνο ανταπόκρισης από πλευράς της εταιρίας" },
                        { value: "Τη γενικότερη ποιότητα εξυπηρέτησης" },
                        { value: "Την ανεπάρκεια των απαντήσεων που μου δόθηκαν" },
                        { value: "Προβλήματα κατά την υπαναχώρηση" },
                        { value: "Την έναρξη λειτουργίας της προμήθειας ενέργειας" },
                    ]
                },
                rae: {
                    default: "ΕΞΥΠΗΡΕΤΗΣΗ",
                    children: [
                        { value: "Καθυστέρηση στην αλλαγή Προμηθευτή", label: "ΚΑΘΥΣΤΕΡΗΣΕΙΣ" },
                        { value: "Τον χρόνο ανταπόκρισης από πλευράς της εταιρίας", label: "ΚΑΘΥΣΤΕΡΗΣΕΙΣ" },
                        { value: "Τη γενικότερη ποιότητα εξυπηρέτησης", label: "ΠΟΙΟΤΗΤΑ ΕΞΥΠΗΡΕΤΗΣΗΣ" },
                        { value: "Την ανεπάρκεια των απαντήσεων που μου δόθηκαν", label: "ΑΝΕΠΑΡΚΕΙΑ ΑΠΑΝΤΗΣΕΩΝ" },
                        { value: "Προβλήματα κατά την υπαναχώρηση", label: "ΥΠΑΝΑΧΩΡΗΣΗ" },
                        { value: "Την έναρξη λειτουργίας της προμήθειας ενέργειας", label: "ΚΑΘΥΣΤΕΡΗΣΕΙΣ" },
                    ]
                }

            }
        },
        {
            value: "Την καταχώρηση των στοιχείων μου",
            isVisibleToReceiverKinds: ['ΟΛΑ', "Προμηθευτής Ενέργειας", "Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"],
            labels: {
                client: {
                    children: [
                        { value: "Λάθος στην καταχώρηση των στοιχείων μου" },
                        { value: "Αλλαγή διεύθυνσης" },
                        { value: "Πρόβλημα με Ιδιοκτήτη" },
                        { value: "Την παραλαβή λογαριασμού από τρίτο πρόσωπο" },
                    ]
                },
                rae: {
                    default: "ΣΤΟΙΧΕΙΑ ΠΕΛΑΤΗ",
                    children: [
                        { value: "Λάθος στην καταχώρηση των στοιχείων μου", label: "ΛΑΘΗ" },
                        { value: "Αλλαγή διεύθυνσης", label: "ΔΙΕΥΘΥΝΣΗ" },
                        { value: "Πρόβλημα με Ιδιοκτήτη", label: "ΙΔΙΟΚΤΗΣΙΑΚΟ ΚΑΘΕΣΤΩΣ" },
                        { value: "Την παραλαβή λογαριασμού από τρίτο πρόσωπο", label: "ΠΑΡΑΛΑΒΗ ΑΠΌ ΤΡΙΤΟΥΣ" },
                    ]
                }

            }
        },
        {
            value: "Την αλλαγή Προμηθευτή",
            isVisibleToReceiverKinds: ['ΟΛΑ', "Προμηθευτής Ενέργειας", "Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"],
            labels: {
                client: {
                    children: [
                        { value: "Την άρνηση από την εταιρία για αλλαγή Προμηθευτή" },
                        { value: "Την αδυναμία από την εταιρία για αλλαγή Προμηθευτή" },
                    ]
                },
                rae: {
                    default: "ΑΛΛΑΓΗ ΠΡΟΜΗΘΕΥΤΗ",
                    children: [
                        { value: "Την άρνηση από την εταιρία για αλλαγή Προμηθευτή", label: "ΑΡΝΗΣΗ ΑΛΛΑΓΗΣ" },
                        { value: "Την αδυναμία από την εταιρία για αλλαγή Προμηθευτή", label: "ΑΔΥΝΑΜΙΑ ΑΛΛΑΓΗΣ" },
                    ]
                }

            }
        },
        {
            value: "Άλλο",
            isVisibleToReceiverKinds: ['ΟΛΑ', "Προμηθευτής Ενέργειας", "Διαχειριστής Δικτύου", "Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"],
            labels: {
                client: {
                    // children: [                        
                    //     { value: "Άλλο"},
                    // ]
                },
                rae: {
                    default: "ΑΛΛΟ",
                    // children: [                        
                    //     { value: "Άλλο", label: "ΑΛΛΟ" },
                    // ]
                }

            }
        },
        {
            value: "Νέα σύνδεση",
            isVisibleToMultipleCriteria: [
                {
                    receiverKind: ["Διαχειριστής Δικτύου"], energyKinds: ["Ηλεκτρική Ενέργεια", "Φυσικό Αέριο", "ΟΛΑ", "Ηλεκτρική Ενέργεια & Φυσικό Αέριο"]
                },
                {
                    receiverKind: ["Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"], energyKinds: []
                },
                {
                    receiverKind: ["ΟΛΑ"], energyKinds: []
                }
            ],
            labels: {
                client: {
                    children: [
                        { value: "Τις συνθήκες υπό τις οποίες μου έγινε προσφορά για νέα παροχή" },
                        { value: "Την κατασκευή νέας παροχής" },
                        { value: "Τη σύνδεση νέου μετρητή σε υπάρχουσα παροχή" },
                    ]
                },
                rae: {
                    default: "ΝΕΑ ΣΥΝΔΕΣΗ",
                    children: [
                        { value: "Τις συνθήκες υπό τις οποίες μου έγινε προσφορά για νέα παροχή", label: "ΠΡΟΣΦΟΡΑ ΝΕΑΣ ΠΑΡΟΧΗΣ" },
                        { value: "Την κατασκευή νέας παροχής", label: "ΚΑΤΑΣΚΕΥΗ ΝΕΑΣ ΠΑΡΟΧΗΣ" },
                        { value: "Τη σύνδεση νέου μετρητή σε υπάρχουσα παροχή", label: "ΣΥΝΔΕΣΗ ΝΕΟΥ ΜΕΤΡΗΤΗ" },
                    ]
                }

            }
        },
        {
            value: "Εργασίες σε μετρητές",
            isVisibleToMultipleCriteria: [
                {
                    receiverKind: ["Διαχειριστής Δικτύου"], energyKinds: ["Ηλεκτρική Ενέργεια", "Φυσικό Αέριο", "ΟΛΑ", "Ηλεκτρική Ενέργεια & Φυσικό Αέριο"]
                },
                {
                    receiverKind: ["Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"], energyKinds: []
                },
                {
                    receiverKind: ["ΟΛΑ"], energyKinds: []
                }
            ],
            labels: {
                client: {
                    children: [
                        { value: "Την επανασύνδεση του μετρητή μετά από την τακτοποίηση οφειλής λόγω χρέους" },
                        { value: "Τη διακοπή ή επανασύνδεση της παροχής μετά από αίτηση μου" },
                        { value: "Τον έλεγχο της αξιοπιστίας του μετρητή" },
                    ]
                },
                rae: {
                    default: "ΕΠΑΝΑΣΥΝΔΕΣΗ",
                    children: [
                        { value: "Την επανασύνδεση του μετρητή μετά από την τακτοποίηση οφειλής λόγω χρέους", label: "ΠΑΛΑΙΕΣ ΟΦΕΙΛΕΣ" },
                        { value: "Τη διακοπή ή επανασύνδεση της παροχής μετά από αίτηση μου", label: "ΔΙΑΚΟΠΗ Ή ΕΠΑΝΑΣΥΝΔΕΣΗ" },
                        { value: "Τον έλεγχο της αξιοπιστίας του μετρητή", label: "ΑΞΙΟΠΙΣΤΙΑ ΜΕΤΡΗΣΗΣ" },
                    ]
                }

            }
        },
        {
            value: "Μετρήσεις κατανάλωσης",
            isVisibleToMultipleCriteria: [
                {
                    receiverKind: ["Διαχειριστής Δικτύου"], energyKinds: ["Ηλεκτρική Ενέργεια", "ΟΛΑ", "Ηλεκτρική Ενέργεια & Φυσικό Αέριο"]
                },
                {
                    receiverKind: ["Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"], energyKinds: []
                },
                {
                    receiverKind: ["ΟΛΑ"], energyKinds: []
                }
            ],
            labels: {
                client: {
                    children: [
                        { value: "Λανθασμένη Καταμέτρηση" },
                        { value: "Μη τακτική καταμέτρηση" },
                        { value: "Καταγγελία και καταλογισμό Ρευματοκλοπής" },
                    ]
                },
                rae: {
                    default: "ΜΕΤΡΗΣΕΙΣ Η.Ε.",
                    children: [
                        { value: "Λανθασμένη Καταμέτρηση", label: "ΛΑΘΗ ΜΕΤΡΗΣΗΣ" },
                        { value: "Μη τακτική καταμέτρηση", label: "ΣΥΧΝΟΤΗΤΑ ΜΕΤΡΗΣΗΣ" },
                        { value: "Καταγγελία και καταλογισμό Ρευματοκλοπής", label: "ΡΕΥΜΑΤΟΚΛΟΠΗ" },
                    ]
                }

            }
        },
        {
            value: "Μετρήσεις",
            isVisibleToMultipleCriteria: [
                {
                    receiverKind: ["Διαχειριστής Δικτύου"], energyKinds: ["Φυσικό Αέριο", "ΟΛΑ", "Ηλεκτρική Ενέργεια & Φυσικό Αέριο"]
                },
                {
                    receiverKind: ["Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"], energyKinds: []
                },
                {
                    receiverKind: ["ΟΛΑ"], energyKinds: []
                }
            ],
            labels: {
                client: {
                    children: [
                        { value: "Λανθασμένη Καταμέτρηση" },
                        { value: "Μη τακτική καταμέτρηση" },
                    ]
                },
                rae: {
                    default: "ΜΕΤΡΗΣΕΙΣ Φ.Α.",
                    children: [
                        { value: "Λανθασμένη Καταμέτρηση", label: "ΛΑΘΗ ΜΕΤΡΗΣΗΣ" },
                        { value: "Μη τακτική καταμέτρηση", label: "ΣΥΧΝΟΤΗΤΑ ΜΕΤΡΗΣΗΣ" },
                    ]
                }

            }
        },
        {
            value: "Τεχνικά προβλήματα",
            isVisibleToMultipleCriteria: [
                {
                    receiverKind: ["Διαχειριστής Δικτύου"], energyKinds: ["Ηλεκτρική Ενέργεια", "Φυσικό Αέριο", "ΟΛΑ", "Ηλεκτρική Ενέργεια & Φυσικό Αέριο"]
                },
                {
                    receiverKind: ["Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"], energyKinds: []
                },
                {
                    receiverKind: ["ΟΛΑ"], energyKinds: []
                }
            ],
            labels: {
                client: {
                    children: [
                        { value: "Καθυστέρηση στην αλλαγή Προμηθευτή" },
                        { value: "Αδυναμία αλλαγής Προμηθευτή" },
                        { value: "Βλάβη Δικτύου" },
                        { value: "Αυξομειώσεις τάσεως" },
                        { value: "Αποζημίωση συσκευών" }
                    ]
                },
                rae: {
                    default: "ΤΕΧΝΙΚΑ ΠΡΟΒΛΗΜΑΤΑ",
                    children: [
                        { value: "Καθυστέρηση στην αλλαγή Προμηθευτή", label: "ΚΑΘΥΣΤΕΡΗΣΕΙΣ" },
                        { value: "Αδυναμία αλλαγής Προμηθευτή", label: "ΑΛΛΑΓΗ ΠΡΟΜΗΘΕΥΤΗ" },
                        { value: "Βλάβη Δικτύου", label: "ΒΛΑΒΗ ΔΙΚΤΥΟΥ" },
                        { value: "Αυξομειώσεις τάσεως", label: "ΑΥΞΟΜΕΙΩΣΗ ΤΑΣΗΣ" },
                        { value: "Αποζημίωση συσκευών", label: "ΑΠΟΖΗΜΙΩΣΗ" },
                    ]
                }

            }
        },
        {
            value: "Εξυπηρέτηση Πελατών",
            isVisibleToMultipleCriteria: [
                {
                    receiverKind: ["Διαχειριστής Δικτύου"], energyKinds: ["Ηλεκτρική Ενέργεια", "Φυσικό Αέριο", "ΟΛΑ", "Ηλεκτρική Ενέργεια & Φυσικό Αέριο"]
                },
                {
                    receiverKind: ["Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας"], energyKinds: []
                },
                {
                    receiverKind: ["ΟΛΑ"], energyKinds: []
                }
            ],
            labels: {
                client: {
                    children: [
                        { value: "Αίτηση για ευνοϊκότερο Διακανονισμό" },
                        { value: "Τη γενικότερη ποιότητα εξυπηρέτησης" },
                        { value: "Καθυστέρηση ένταξης ή απένταξης από ΚΟΤ" },
                    ]
                },
                rae: {
                    default: "ΕΞΥΠΗΡΕΤΗΣΗ ΔΙΚΤΥΟΥ",
                    children: [
                        { value: "Αίτηση για ευνοϊκότερο Διακανονισμό", label: "ΔΙΑΚΑΝΟΝΙΣΜΟΙ" },
                        { value: "Τη γενικότερη ποιότητα εξυπηρέτησης", label: "ΠΟΙΟΤΗΤΑ" },
                        { value: "Καθυστέρηση ένταξης ή απένταξης από ΚΟΤ", label: "ΚΟΙΝΩΝΙΚΟ ΤΙΜΟΛΟΓΙΟ" },
                    ]
                }

            }
        },
    ],
}