import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import { CommonLoading } from 'react-loadingg';
import { optionsDropdown } from '../../assets/js/options.js';
import 'antd/dist/antd.css';
import { Cascader } from 'antd';
import { ViewType, StateActionsLiterals } from '../../assets/js/stateActionListerals'

import axios from 'axios';
import Modal from 'react-modal';

import background from "../../assets/img/header-background.jpg";

import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Tooltip,
  Label,
  FormText,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';

class Datepicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: ''
    };

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
  }

  handleChangeStartDate(e) {
    this.props.updateStartDateProp(e);
    this.setState({ startDate: e })
  }

  handleChangeEndDate(e) {
    this.props.updateEndDateProp(e);
    this.setState({ endDate: e })
  }


  render() {
    return (
      <>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Από"
                  }}
                  timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                      new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                      new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  onChange={this.handleChangeStartDate}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Έως"
                  }}
                  timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                      new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                      new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  onChange={this.handleChangeEndDate}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

function base64toBlob(base64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}


class exportFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      loading: true,
      instance: [],
      workflow: [],
      user: [],
      logged_user: [],
      steps: [],
      files: [],
      enabled: true,
      fileUpload: null,
      comment: '',
      buttonCondition: 0,
      buttonMessage: '',
      SuppliersMaintainers: [],
      complaintCategories: [],
      startDate: '-',
      endDate: '-',
      selectedSupplierMaintainer: '-',
      selectedComplaint: 'ΟΛΑ',
      instanceSubject: 'ΟΛΑ',
      energyKind: 'ΟΛΑ',
      receiverKind: 'ΟΛΑ',
      receiverName: 'ΟΛΑ',
      isZipChecked: false,
      gasMaintainers: [],
      electrMaintainers: [],
      gasSuppliers: [],
      electrSuppliers: [],
      gasAndElectrSuppliers: [],
      maintainers: [],
      suppliers: [],
      allSuppliers: [],
      isLoading: false,
      isAdmin: true,
      user: {},
      dynamicCategoryOptions: StateActionsLiterals.GenerateOptions(ViewType.rae, 'ΟΛΑ', 'ΟΛΑ')
    };

    this.updateStartDate = this.updateStartDate.bind(this);
    this.updateEndDate = this.updateEndDate.bind(this);
    this.changeEnergyKind = this.changeEnergyKind.bind(this);
    this.changeReceiverKind = this.changeReceiverKind.bind(this);
    this.changeReceiverName = this.changeReceiverName.bind(this);
    this.toggleZipCheck = this.toggleZipCheck.bind(this);

    this.dynamicOptions = StateActionsLiterals.GenerateOptions(ViewType.rae);
  }

  async componentDidMount() {
    if (localStorage.getItem('token')) {

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/name", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            if (result[0] != -1) {
              this.setState({
                user: result,
                isAdmin: false
              });
            }
          }
        )
    }
    const handle = this.props.match.params.id;
    const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/lists/", {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
      .then(
        (res) => {
          let m = res.data.m
          let filteredMaintainers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
          m.forEach(function (element, index) {
            filteredMaintainers.push({
              value: element.Name,
              label: element.DisplayName
            });
          });
          // var filteredMAINTAINERS = filteredMaintainers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')
          let uniqueMaintainers = [...new Set(filteredMaintainers.map(({ value }) => value))].map(e => filteredMaintainers.find(({ value }) => value == e));

          let s = res.data.s
          let filteredSuppliers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
          s.forEach(function (element, index) {
            filteredSuppliers.push({
              value: element.Name,
              label: element.DisplayName
            });
          });
          // var filteredSUPPLIERS = filteredSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')
          let uniqueSuppliers = [...new Set(filteredSuppliers.map(({ value }) => value))].map(e => filteredSuppliers.find(({ value }) => value == e));

          let all = res.data.all
          let filteredAllSuppliers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
          all.forEach(function (element, index) {
            filteredAllSuppliers.push({
              value: element.Name,
              label: element.DisplayName
            });
          });
          // var filteredALL = filteredAllSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')
          let uniqueAll = [...new Set(filteredAllSuppliers.map(({ value }) => value))].map(e => filteredAllSuppliers.find(({ value }) => value == e));

          let gm = res.data.gm
          let filteredGasMaintainers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
          gm.forEach(function (element, index) {
            filteredGasMaintainers.push({
              value: element.Name,
              label: element.DisplayName
            });
          });
          var filteredGM = filteredGasMaintainers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')


          let em = res.data.em
          let filteredElectricMaintainers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
          em.forEach(function (element, index) {
            filteredElectricMaintainers.push({
              value: element.Name,
              label: element.DisplayName
            });
          });
          var filteredEM = filteredElectricMaintainers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')

          let gs = res.data.gs
          let filteredGasSuppliers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
          gs.forEach(function (element, index) {
            filteredGasSuppliers.push({
              value: element.Name,
              label: element.DisplayName
            });
          });
          var filteredGS = filteredGasSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')

          let es = res.data.es
          let filteredElectricSuppliers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
          es.forEach(function (element, index) {
            filteredElectricSuppliers.push({
              value: element.Name,
              label: element.DisplayName
            });
          });
          var filteredES = filteredElectricSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')

          let egs = res.data.egs
          let filteredGasANDElectricSuppliers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
          egs.forEach(function (element, index) {
            filteredGasANDElectricSuppliers.push({
              value: element.Name,
              label: element.DisplayName
            });
          });
          var filteredEGS = filteredGasANDElectricSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')


          this.setState({
            gasMaintainers: filteredGM,
            electrMaintainers: filteredEM,
            gasSuppliers: filteredGS,
            electrSuppliers: filteredES,
            gasAndElectrSuppliers: filteredEGS,
            maintainers: uniqueMaintainers,
            suppliers: uniqueSuppliers,
            allSuppliers: uniqueAll,
            instanceId: uuidv4(),
            instanceId2: uuidv4()
          });
        },
        (error) => {
          this.setState({
            error
          });
        }
      )

    await fetch(process.env.REACT_APP_API_LINK + "/api/dynamic/complaintcategories/", {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            complaintCategories: result
          })
        },
        (error) => {
          this.setState({
            error
          });
        }
      )


  };

  toggleZipCheck() {
    this.setState(prevState => ({ isZipChecked: !prevState.isZipChecked }));
  }

  getDynamicOptionLiteralCategory = (valueKey) => {
    let result = this.state.dynamicCategoryOptions.filter(x => x.value == valueKey.trim())[0] ?? null;
    if (result == null) {
      return valueKey;
    }
    return result.label;
  }
  getDynamicOptionLiteralSubCategory = (valueKey) => {
    let result = this.state.dynamicCategoryOptions.filter(x => x.children.filter(y => y.value == valueKey.trim()).length > 0)[0] ?? null;
    if (result == null) {
      return valueKey;
    }
    return result.children.filter(x => x.value == valueKey.trim())[0].label;
  }

  changeReceiverKind = (receiverKind) => {
    let value = receiverKind['value'];
    this.setState(
      {
        receiverKind: value,
        dynamicCategoryOptions: StateActionsLiterals.GenerateOptions(ViewType.rae, value, this.state.energyKind)
      }, () =>
      console.log(`Receiver selected:`, this.state.receiverKind)
    );
  };

  changeEnergyKind = (energyKind) => {
    this.state.energyKind = energyKind['value'];
    if (this.state.energyKind == 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο') {
      this.state.receiverKind = 'Προμηθευτής Ενέργειας'
      console.log(`Receiver selected:`, this.state.receiverKind)
    }
    this.setState(
      {
        energyKind: this.state.energyKind,
        receiverKind: this.state.receiverKind,
        dynamicCategoryOptions: StateActionsLiterals.GenerateOptions(ViewType.rae, this.state.receiverKind, this.state.energyKind)
      }, () =>
      console.log(`Type selected:`, this.state.energyKind)

    );
  };

  changeReceiverName = (receiverName) => {
    this.setState({ receiverName: receiverName['value'] }, () =>

      console.log(`Receiver Name selected:`, this.state.receiverName)
    );
  }


  updateStartDate = (startdate) => {
    try {

      this.setState({ startDate: startdate.toDate().toLocaleString('en-CA').split(',')[0] });
    }
    catch
    {
      this.setState({ startDate: '-' });
    }
  }

  updateEndDate = (enddate) => {
    try {
      let endDatetoDate = enddate.toDate();
      const addOneDay = new Date(new Date(endDatetoDate).setDate(endDatetoDate.getDate() + 1))
      this.setState({ endDate: addOneDay.toLocaleString('en-CA').split(',')[0] });
    }
    catch
    {
      this.setState({ endDate: '-' });
    }
  }

  ChangeSupplierMaintainer = (e) => {
    this.setState({
      selectedSupplierMaintainer: e.target.value
    })
  }

  ChangeComplaint = (e) => {
    this.setState({
      selectedComplaint: e.target.value
    })
    console.log(this.state.selectedComplaint);
  }

  changeInstanceCategoryANDSubject = (event) => {
    this.setState({ selectedComplaint: event[0], instanceSubject: event[1] }, () =>
      console.log(this.state.selectedComplaint + this.state.instanceSubject)
    );
  }

  ParameterExport = () => {
    if (localStorage.getItem('token')) {
      this.setState({ isLoading: true });
      fetch(process.env.REACT_APP_API_LINK + '/api/export/parameterexportexcel/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
          // 'responseType': 'blob'
        }),
        body: JSON.stringify({
          'startDate': this.state.startDate,
          'endDate': this.state.endDate,
          'selectedSupplierMaintainer': this.state.selectedSupplierMaintainer,
          'selectedComplaint': this.state.selectedComplaint,
          'instanceSubject': this.state.instanceSubject,
          'receiverKind': this.state.receiverKind,
          'receiverName': (this.state.user && Object.keys(this.state.user).length === 0) ? this.state.receiverName : this.state.user['Name'],
          'energyKind': this.state.energyKind,
          'isZipChecked': this.state.isZipChecked
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          // Create Blob from base64 Excel file
          const excelBlob = base64toBlob(data.file, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
          var excelUrl = URL.createObjectURL(excelBlob);
          const excelLink = document.createElement('a');
          excelLink.href = excelUrl;
          excelLink.setAttribute('download', `Αιτήματα Παραπόνων.xlsx`);
          document.body.appendChild(excelLink);
          excelLink.click();
          excelLink.parentNode.removeChild(excelLink);

          if (this.state.isZipChecked) {
            const zipBlob = base64toBlob(data.zipFile, 'application/zip');
            var zipUrl = URL.createObjectURL(zipBlob);
            const zipLink = document.createElement('a');
            zipLink.href = zipUrl;
            zipLink.setAttribute('download', `Στοιχεία Αιτήσεων.zip`);
            document.body.appendChild(zipLink);
            zipLink.click();
            zipLink.parentNode.removeChild(zipLink);
          }
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  shouldDisableButton = () => {
    const { isZipChecked, startDate, endDate } = this.state;

    if (!isZipChecked) {
      return false;
    }

    if (startDate === '-' || endDate === '-') {
      return true;
    }

    // Convert strings to moment dates
    const start = Moment(startDate, 'YYYY-MM-DD');
    const end = endDate !== '-' ? Moment(endDate, 'YYYY-MM-DD') : Moment(); // Use today's date if end date is '-'

    // Calculate difference
    const diff = end.diff(start, 'days');

    // Return true if the difference is more than 30 days (1 month)
    return diff > 31;
  }


  render() {
    const { energyKind, receiverKind, receiverName } = this.state;
    return (
      <>
        <InstanceHeader />
        {/* <div className="header pb-4" style={{background: "linear-gradient(to right, #FFFFFF, #4A002B)"}}> */}
        <div className="header pb-4" style={{ backgroundImage: `url(${background})` }}>
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col lg="8" xl="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Παραμετρική Εξαγωγή Αιτημάτων
                          </span>

                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

          </Container>

        </div>

        <Container >

          <br /><br />
          <Form>
            <Row>
              <Col xs={12} md={12}>
                <Datepicker updateStartDateProp={this.updateStartDate} updateEndDateProp={this.updateEndDate} />
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6}>
                <Select
                  value={energyKind['value']}
                  onChange={this.changeEnergyKind}
                  options={[
                    { value: 'ΟΛΑ', label: "'Ολα" },
                    { value: 'Φυσικό Αέριο', label: 'Φυσικό Αέριο' },
                    { value: 'Ηλεκτρική Ενέργεια', label: 'Ηλεκτρική Ενέργεια' },
                    { value: 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο', label: 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο' },
                  ]}
                  placeholder={"Είδος Ενέργειας"}
                />
              </Col>
              {this.state.energyKind == 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο' ?
                <Col xs={6} md={6}>
                  <Select
                    value={'Προμηθευτής Ενέργειας'}
                    isDisabled={true}
                    placeholder={'Προμηθευτής Ενέργειας'}
                  />
                </Col>
                :
                <>
                  <Col xs={6} md={6}>
                    <Select
                      // defaultValue={{ value: 'ΟΛΑ', label: 'Όλα' }}
                      value={receiverKind['value']}
                      isDisabled={!this.state.isAdmin}
                      onChange={this.changeReceiverKind}
                      options={[
                        { value: 'ΟΛΑ', label: "'Ολα" },
                        { value: 'Διαχειριστής Δικτύου', label: 'Διαχειριστής Δικτύου' },
                        { value: 'Προμηθευτής Ενέργειας', label: 'Προμηθευτής Ενέργειας' },
                        { value: 'Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας', label: 'Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας' },
                      ]}
                      placeholder={"Επιλογή Είδος Παραλήπτη"}
                    />
                  </Col>
                </>
              }

              {this.state.energyKind == 'Φυσικό Αέριο' && this.state.receiverKind == 'Διαχειριστής Δικτύου' && this.state.isAdmin ?
                <>
                  <Col xs={6} md={6}>
                    <Select
                      defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                      value={receiverName['value']}
                      onChange={this.changeReceiverName}
                      options={this.state.gasMaintainers}
                      placeholder={"Επιλογή διαχειριστή (Φυσικό Αέριο)"}
                    />
                  </Col>
                </>
                : <> {this.state.energyKind == 'Φυσικό Αέριο' && this.state.receiverKind == 'Προμηθευτής Ενέργειας' && this.state.isAdmin ?
                  <>
                    <Col xs={6} md={6}>
                      <Select
                        defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                        value={receiverName['value']}
                        onChange={this.changeReceiverName}
                        options={this.state.gasSuppliers}
                        placeholder={"Επιλογή προμηθευτή (Φυσικό Αέριο)"}
                      />
                    </Col>
                  </> : <></>
                }
                </>
              }

              {this.state.energyKind == 'Ηλεκτρική Ενέργεια' && this.state.receiverKind == 'Διαχειριστής Δικτύου' && this.state.isAdmin ?
                <>
                  <Col xs={6} md={6}>
                    <Select
                      defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                      value={receiverName['value']}
                      onChange={this.changeReceiverName}
                      options={this.state.electrMaintainers}
                      placeholder={"Επιλογή διαχειριστή (Ηλεκτρική Ενέργεια)"}
                    />
                  </Col>
                </>
                : <> {this.state.energyKind == 'Ηλεκτρική Ενέργεια' && this.state.receiverKind == 'Προμηθευτής Ενέργειας' && this.state.isAdmin ?
                  <>
                    <Col xs={6} md={6}>
                      <Select
                        defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                        value={receiverName['value']}
                        onChange={this.changeReceiverName}
                        options={this.state.electrSuppliers}
                        placeholder={"Επιλογή προμηθευτή (Ηλεκτρική Ενέργεια)"}
                      />
                    </Col>
                  </> : <></>
                }
                </>
              }
              {this.state.energyKind == 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο' && this.state.isAdmin ?
                <>
                  <Col xs={6} md={6}>
                    <Select
                      defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                      value={receiverName['value']}
                      onChange={this.changeReceiverName}
                      options={this.state.gasSuppliers}
                      placeholder={"Επιλογή προμηθευτή (Ηλεκτρική Ενέργεια & Φυσικό Αέριο)"}
                    />
                  </Col>
                </>
                : <></>}
              <br />
              <br />
              {(this.state.receiverKind == 'ΟΛΑ' || this.state.receiverKind == 'Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας') && this.state.energyKind != 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο' && this.state.isAdmin ?
                <Col xs={6} md={6}>
                  <Select
                    value={receiverName['value']}
                    onChange={this.changeReceiverName}
                    options={this.state.allSuppliers}
                    placeholder={"Επιλογή παραλήπτη"}
                  />
                </Col>
                :
                <></>}
              {this.state.receiverKind == 'Διαχειριστής Δικτύου' && this.state.energyKind == 'ΟΛΑ' && this.state.isAdmin ?
                <Col xs={6} md={6}>
                  <Select
                    value={receiverName['value']}
                    onChange={this.changeReceiverName}
                    options={this.state.maintainers}
                    placeholder={"Επιλογή Διαχειριστή"}
                  />
                </Col>
                :
                <></>}
              {this.state.receiverKind == 'Προμηθευτής Ενέργειας' && this.state.energyKind == 'ΟΛΑ' && this.state.isAdmin ?
                <Col xs={6} md={6}>
                  <Select
                    value={receiverName['value']}
                    onChange={this.changeReceiverName}
                    options={this.state.suppliers}
                    placeholder={"Επιλογή Προμηθευτή"}
                  />
                </Col>
                :
                <></>}
            </Row>
            <br></br>
            <Row>
              <Col lg="6">
                <React.Fragment>
                  <Row>
                    <Col style={{ paddingTop: '13px' }}>
                      <h4>Παρακαλούμε Επιλέξτε Κατηγορία Παραπόνου</h4>
                      <Cascader
                        id={'cascader'}
                        className={"dropdown"}
                        options={this.state.dynamicCategoryOptions}
                        // options={this.dynamicOptions} //StateActionsLiterals.GenerateOptions(ViewType.rae)}
                        onChange={event => this.changeInstanceCategoryANDSubject(event)}
                        placeholder={"Επιλέξτε Κατηγορία/Υποκατηγορία Αιτήματος"}
                        style={{ width: '100%', alignItems: 'center' }}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              </Col>
            </Row>
            <Row style={{ paddingTop: '13px' }}>
              <Col lg="4">
                <Card style={{ border: '1px solid #000' }}>
                  <CardBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Form>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.isZipChecked}
                          onChange={this.toggleZipCheck}
                          style={{ transform: 'scale(1.5)' }}
                        />{' '}
                        Κατέβασμα αρχείων αιτήσεων
                      </Label>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>


            <br />
            <Row>
              <Col xs={6}>

                <Button
                  color="success"
                  onClick={() => { this.ParameterExport() }}
                  disabled={this.shouldDisableButton()}
                >
                  {this.state.isLoading ? 'Περιμένετε...' : 'Εξαγωγή'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>

      </>
    );

  }
}

export default exportFilter;
